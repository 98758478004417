import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import NewLandingLayout from '../../../components/NewLandingComponents/NewLandingLayout/NewLandingLayout';

interface Props {}

const ConsumerProtectionPolicy: React.FC<Props> = () => {
  return (
    <NewLandingLayout>
      <div className="scalex-new-privacy-policy">
        <Container>
          <Row>
            <Col>
              <h2>Consumer Protection Policy</h2>

              <div className="scalex-new-privacy-policy__wrapper">
                <h4 className="mt-5">Introduction</h4>
                <p>
                  Scalex Technologies Limited is committed to providing
                  transparent, fair, and secure financial services to our valued
                  customers in Nigeria and across the world. Our Consumer
                  Protection Policy is designed to outline the principles and
                  practices that guide our interactions with customers, ensuring
                  their rights, privacy, and satisfaction are prioritized.
                </p>

                <div>
                  <h4 className="my-5">Key Principles:</h4>

                  <h4>1. Transparency: </h4>
                  <p>
                    We pledge to communicate clearly and openly about our
                    financial products, services, fees, and terms. We will
                    provide customers with all relevant information to make
                    informed decisions about their financial transactions.
                  </p>

                  <h4 className="mt-5">2. Fair Practices:</h4>
                  <p>
                    Scalex Technologies Limited is dedicated to maintaining
                    fairness in all interactions with customers. We will ensure
                    that our products and services are designed and delivered
                    with impartiality, and we will treat all customers
                    equitably.
                  </p>
                  <h4 className="mt-5">3. Privacy and Data Security:</h4>

                  <span>
                    Safeguarding the personal and financial information of our
                    customers is of paramount importance. We adhere to strict
                    data protection measures, complying with relevant privacy
                    laws, and employing robust security systems to protect
                    customer data.
                  </span>

                  <h4 className="mt-5"> 4. Complaint Resolution:</h4>
                  <span>
                    Scalex Technologies Limited is committed to resolving
                    customer complaints promptly and efficiently. We have
                    established clear procedures for submitting and addressing
                    complaints, ensuring that customers have a straightforward
                    process to voice their concerns.
                  </span>

                  <h4 className="mt-5"> 5. Customer Rights: </h4>
                  <h4 className="mt-5"> 5.1. Right to Information: </h4>

                  <p>
                    Customers have the right to receive comprehensive and clear
                    information about our financial products, including terms,
                    conditions, fees, and risks associated with each product.
                  </p>

                  <h4 className="mt-5">5.2. Right to Fair Treatment:</h4>
                  <p>
                    {' '}
                    Every customer will be treated with fairness, integrity, and
                    respect. Discrimination, harassment, or unfair practices
                    will not be tolerated.
                  </p>
                  <h4 className="mt-5"> 5.3. Right to Privacy:</h4>
                  <p>
                    Customer data will be handled with the utmost
                    confidentiality. Scalex Technologies Limited will not share
                    customer information with third parties without explicit
                    consent, except as required by law.
                  </p>

                  <h4 className="mt-5">5.4. Right to Redress:</h4>
                  <p>
                    Customers have the right to seek redress for any grievances
                    through our established complaint resolution process.
                  </p>

                  <h4 className="mt-5">6. Compliance:</h4>
                  <p>
                    Scalex Technologies Limited is committed to complying with
                    all relevant laws and regulations governing consumer
                    protection in Nigeria. Not limited to the following
                    legislations: Consumer Protection Act 2004, Data Protection
                    Act 2023, Central Bank of Nigeria directives and guidelines.
                    We will regularly review and update our policies and
                    procedures to ensure ongoing compliance and the highest
                    standards of customer protection.
                  </p>

                  <h4 className="mt-5">7. Communication and Training:</h4>
                  <p>
                    {' '}
                    We will regularly communicate our Consumer Protection Policy
                    to all employees, ensuring they are well-versed in these
                    principles. Training programs will be implemented to enhance
                    staff awareness and commitment to providing exemplary
                    consumer protection.
                  </p>
                  <h4 className="mt-5">8. Review and Amendments:</h4>
                  <p>
                    This Consumer Protection Policy will be periodically
                    reviewed to ensure its relevance and effectiveness.
                    Amendments will be made as necessary to align with changes
                    in laws, regulations, and business practices.
                  </p>

                  <p>
                    By adhering to these principles, Scalex Technologies Limited
                    aims to build trust, foster long-term relationships, and
                    contribute to the financial well-being of our valued
                    customers in Nigeria.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </NewLandingLayout>
  );
};

export default ConsumerProtectionPolicy;
