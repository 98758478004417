// Orders Types

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR'
export const ORDER_ERROR = 'ORDER_ERROR'

export const SELL_RENEGOTIATED_ORDER = 'SELL_RENEGOTIATED_ORDER'

export const GET_BUY_ORDERS = 'GET_BUY_ORDERS'
export const GET_SELL_ORDERS = 'GET_SELL_ORDERS'

export const GET_BUY_ORDERS_BY_USER_ID = 'GET_BUY_ORDERS_BY_USER_ID'
export const GET_SELL_ORDERS_BY_USER_ID = 'GET_SELL_ORDERS_BY_USER_ID'

export const GET_PUBLIC_BUY_ORDER = 'GET_PUBLIC_BUY_ORDER'
export const GET_PUBLIC_SELL_ORDER = 'GET_PUBLIC_SELL_ORDER'

export const GET_MARKET_PRICE = 'GET_MARKET_PRICE'

export const GET_ORDER = 'GET_ORDER'
export const GET_PUBLIC_ORDER = "GET_PUBLIC_ORDER"

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const DELETE_ORDER = 'DELETE_ORDER'