import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import BridgeProductImage from '../../../images/New/bridge-product-image.svg';
import BridgeProductImageMobile from '../../../images/New/bridge-accordion-mobile.png';

import P2PProductImage from '../../../images/New/p2p-product-image.svg';
import P2PProductImageMobile from '../../../images/New/p2p-accordion-mobile.png';

import Web3ProductImage from '../../../images/New/web3-product-image.svg';
import Web3ProductImageMobile from '../../../images/New/web3-accordion-mobile.png';

import ApiProductImage from '../../../images/New/api-product-image.svg';
import ApiProductImageMobile from '../../../images/New/api-accordion-mobile.png';

import CloseModal from '../../../images/New/close-dark.svg';
import CloseMobile from '../../../images/New/close-mobile.svg';

import useWindowDimensions from '../../WindowDimension/WindowDimension';

type Props = {
  modal: boolean;
  toggle: any;
  children: JSX.Element;
  selectedItem: number;
};

const ProductModalLayout: React.FC<Props> = ({
  modal,
  toggle,
  children,
  selectedItem,
}) => {
  const { width } = useWindowDimensions();

  return (
    <Modal isOpen={modal} toggle={toggle} className="scalex-new-product-modal">
      <ModalBody>
        <div className="d-flex scalex-new-modal__wrapper">
          {width <= 780 ? (
            <img
              src={
                selectedItem === 1
                  ? BridgeProductImageMobile
                  : selectedItem === 2
                  ? P2PProductImageMobile
                  : selectedItem === 3
                  ? Web3ProductImageMobile
                  : selectedItem === 4
                  ? ApiProductImageMobile
                  : BridgeProductImageMobile
              }
              alt="about"
              className="scalex-new-modal--image"
            />
          ) : (
            <img
              src={
                selectedItem === 1
                  ? BridgeProductImage
                  : selectedItem === 2
                  ? P2PProductImage
                  : selectedItem === 3
                  ? Web3ProductImage
                  : selectedItem === 4
                  ? ApiProductImage
                  : BridgeProductImage
              }
              alt="about"
              width={537}
              className="scalex-new-modal--image"
            />
          )}

          {children}
        </div>
        {width <= 780 ? (
          <div onClick={toggle} className="scalex-new-modal--close-mobile">
            <img src={CloseMobile} alt="about" />
          </div>
        ) : (
          <div onClick={toggle} className="scalex-new-modal--close">
            <img src={CloseModal} alt="about" />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProductModalLayout;
