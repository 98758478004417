import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Alchemy from '../../../images/New/alchemy-pay.svg';
import Solana from '../../../images/New/solana.svg';
import Gamic from '../../../images/New/gamic.svg';
import Quiver from '../../../images/New/quiver.svg';
import circle from '../../../images/New/circle.svg';
import Fonbnk from '../../../images/New/fonbnk.svg';
import Espresso from '../../../images/New/espresso-cash.svg';
import Finna from '../../../images/New/finna-protocol.svg';
import Bitnob from '../../../images/New/bitnob.svg';
import Xend from '../../../images/New/xendbrige.svg';
import Surtii from '../../../images/New/surtii.svg';

import Slider from 'react-slick';
import { settingsTwo } from '../../../utils/carousel';
import useWindowDimensions from '../../WindowDimension/WindowDimension';

type Props = {};

const NewSectionFive: React.FC<Props> = () => {
  const { width } = useWindowDimensions();

  return (
    <div className="scalex-new-section-five">
      <Container>
        <Row>
          <h3 className="text-center">Our partners</h3>
          {width < 700 ? (
            <Col>
              <div className="scalex-new-section-five--images-carousel">
                <Slider {...settingsTwo}>
                  <img src={Solana} alt="icon" />
                  <img src={circle} alt="icon" />
                  <img src={Gamic} alt="icon" />
                  <img src={Quiver} alt="icon" />
                  <img src={Fonbnk} alt="icon" />
                  <img src={Espresso} alt="icon" />
                  <img src={Finna} alt="icon" />
                  <img src={Alchemy} alt="icon" />
                  <img src={Bitnob} alt="icon" />
                  <img src={Xend} alt="icon" />
                  <img src={Surtii} alt="icon" />
                </Slider>
              </div>
            </Col>
          ) : (
            <Col>
              <div className="d-flex flex-wrap justify-content-center scalex-new-section-five--images">
                <img src={Solana} alt="icon" />
                <img src={circle} alt="icon" />
                <img src={Gamic} alt="icon" />
                <img src={Quiver} alt="icon" />
                <img src={Fonbnk} alt="icon" />
                <img src={Espresso} alt="icon" />
                <img src={Finna} alt="icon" />
                <img src={Alchemy} alt="icon" />
                <img src={Bitnob} alt="icon" />
                <img src={Xend} alt="icon" />
                <img src={Surtii} alt="icon" />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default NewSectionFive;
