// User  Types

export const GET_USER = 'GET_USER'
export const GET_SELLER = 'GET_SELLER'
export const SELLER_ERROR = 'SELLER_ERROR'

export const GET_TOTAL_BALANCE = 'GET_TOTAL_BALANCE'

export const ADD_BANK = 'ADD_BANK'
export const DELETE_BANK  = 'DELETE_BANK'

export const BASIC_INFORMATION = 'BASIC_INFORMATION'
export const INTERMEDIATE_INFORMATION = 'INTERMEDIATE_INFORMATION'
export const ADVANCE_INFORMATION = 'ADVANCE_INFORMATION'
export const VERIFY_PHONE = 'VERIFY_PHONE'

export const GET_ADVANCE_INFORMATION = 'GET_ADVANCE_INFORMATION'

export const USER_SECURITY = 'USER_SECURITY'
export const ACTIVATE_SMS = 'ACTIVATE_SMS'
export const ACTIVATE_2FA = "ACTIVATE_2FA"
export const ACTIVATE_2FA_ERROR = "ACTIVATE_2FA_ERROR"

export const GET_SMS_OTP = 'GET_SMS_OTP'
export const GET_SMS_OTP_ERROR = 'GET_SMS_OTP_ERROR'

export const GET_QR_CODE = 'GET_QR_CODE'
export const GET_QR_CODE_ERROR = 'GET_QR_CODE_ERROR'

export const CHANGE_ACCOUNT_PASSWORD = "CHANGE_ACCOUNT_PASSWORD"

export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL"
export const GET_TESTIMONIAL = 'GET_TESTIMONIAL'
export const TESTIMONIAL_ERROR = "TESTIMONIAL_ERROR"

export const GET_DEPOSIT_HISTORY = 'GET_DEPOSIT_HISTORY'
export const GET_WITHDRAW_HISTORY = 'GET_WITHDRAW_HISTORY'
export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY'

export const REFERRALS = 'REFERRALS'

export const GET_BANK_LIST = 'GET_BANK_LIST'
export const GET_ACCOUNT_NAME = 'GET_ACCOUNT_NAME'

export const USER_ERROR = 'USER_ERROR'