import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

import Facebook from '../../../images/New/facebook.svg';
import Instagram from '../../../images/New/instagram.svg';
import Twitter from '../../../images/New/twitter.svg';
import LinkedIn from '../../../images/New/linkedin.svg';
import Telegram from '../../../images/New/telegram.svg';
import Logo from '../../../images/New/dashboard-logo.svg';

import GreenHouse from '../../../images/greenhouse-capital-logo.png';
import Bitnob from '../../../images/bitnob.png';
import WalletClound from '../../../images/walletcloud.png';
import Busha from '../../../images/busha-logo.png';

interface Props {}

const Footer: React.FC<Props> = () => {
  const checkRoute = useLocation();

  // const settings = {
  //     dots: false,
  //     infinite: false,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  // };

  return (
    <Fragment>
      {checkRoute.pathname === '/' ? (
        <section className="scalex-partners">
          <Container>
            <Row>
              <Col>
                <h4>Partners</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex algin-items-center justify-content-center">
                  <a
                    href="https://greenhouse.capital/"
                    className="d-flex justify-content-center algin-items-center mx-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={GreenHouse} alt="Logo" className="mt-2" />
                  </a>
                  <a
                    href="https://bitnob.com/"
                    className="d-flex justify-content-center algin-items-center mx-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Bitnob} alt="Logo" className="mt-2" />
                  </a>
                  <a
                    href="https://walletcloud.app/"
                    className="d-flex justify-content-center algin-items-center mx-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={WalletClound} alt="Logo" className="mt-2" />
                  </a>
                  <a
                    href="https://busha.co/"
                    className="d-flex  justify-content-center algin-items-center mx-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Busha} alt="Logo" className="mt-2" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <section className="scalex-action">
          <Container>
            <Row className="scalex-action__wrapper">
              <Col md={6}>
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="scalex-action__wrapper--right">
                      <h5>Start buying & selling cryptocurrencies now.</h5>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="scalex-action__wrapper--left">
                  <a href="#get-started">
                    <Button
                      color="link"
                      className="start-trading-white py-2 btn-lg"
                    >
                      Start Trading
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
      <section className="scalex-footer">
        <Container>
          <Row>
            <Col md={4}>
              <div className="d-flex align-items-center mb-3">
                <img src={Logo} alt="Logo" className="logo mr-1" />
                <span className="scalex-sidebar-left--logo-text mr-3">
                  {' '}
                  Scalex
                </span>
              </div>
              <p className="pb-2">
                Scalex ensures you can securely and seamlessly buy and sell
                cryptocurrencies from anyone, anytime, anywhere.{' '}
              </p>
            </Col>
            <Col md={4}>
              <div className="scalex-footer--quick-links d-flex flex-column mt-3">
                <Link to="/fees">Fees</Link>
                <Link to="/kyc">KYC</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </div>
            </Col>
            <Col md={4}>
              <h3 className="scalex-footer--connect mt-4">Connect</h3>
              <div className="scalex-footer--socials mt-3">
                <a
                  href="https://www.instagram.com/scalexp2p/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="Instagram" />
                </a>
                <a
                  href="https://twitter.com/ScalexP2P"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/scalexp2p/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedIn} alt="Linked" />
                </a>
                <a
                  href="https://www.facebook.com/ScalexP2P"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
                <a
                  href="https://t.me/ScalexP2P"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Telegram} alt="Telegram" />
                </a>
              </div>
              <div className="scalex-footer--contact mt-3">
                <p>support@scalex.africa</p>
              </div>
            </Col>
            <Col>
              <hr className="my-3" />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center scalex-footer--copy">
                <p>
                  {' '}
                  &copy; {new Date().getFullYear()} Scalex All Rights Reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Footer;
