import { ICoinbasePriceState, CoinbasePricesAction } from "../../types"
import { COINBASE_BNB_DATA, COINBASE_BTC_DATA, COINBASE_BUSD_DATA, COINBASE_ERROR, COINBASE_ETH_DATA, COINBASE_USDC_DATA, COINBASE_USDT_DATA } from "./types";


const initialState:ICoinbasePriceState = {
    btc_price: {},
    eth_price: {},
    busd_price: {},
    usdc_price:{},
    usdt_price:{},
    bnb_price: {},
    loading: false,
    error: null
}

const CoinbaseReducer = (state = initialState, action: CoinbasePricesAction) => {
    const { type, payload } = action

    switch (type) {
        case COINBASE_BTC_DATA:
            return{
                ...state,
                btc_price: payload,
                loading: true
            }
        case COINBASE_ETH_DATA:
            return{
                ...state,
                eth_price: payload,
                loading: true
            }
        case COINBASE_BUSD_DATA:
            return{
                ...state,
                busd_price: payload,
                loading: true
            }
        case COINBASE_USDC_DATA:
            return{
                ...state,
                usdc_price: payload,
                loading: true
            }
        case COINBASE_USDT_DATA:
            return{
                ...state,
                usdt_price: payload,
                loading: true
            }
        case COINBASE_BNB_DATA:
            return{
                ...state,
                bnb_price: payload,
                loading: true
            }
        case COINBASE_ERROR:
            return{
                ...state,
                error: payload,
                loading: true
            }
        default:
            return state;
    }
}

export default CoinbaseReducer