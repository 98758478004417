import React from 'react';
import { Container } from 'reactstrap';
import Logo2 from '../../../images/scalex-logo-new.svg';

import NewSectionEight from '../NewSectionEight/NewSectionEight';
import { Link } from 'react-router-dom';

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const NewLandingLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="navbar-background">
        <Container>
          <div className="scalex-new-navbar">
            <div>
              <Link to="/">
                <img src={Logo2} alt="scalex-logo" />
              </Link>
            </div>
            {/* <div className="d-flex scalex-new-navbar__nav-list-2">
              <Link to="/signin">
                <Button className="scalex-new-navbar--button">Old user?</Button>
              </Link>
            </div> */}
          </div>
        </Container>
      </div>
      {children}
      <NewSectionEight />
    </>
  );
};

export default NewLandingLayout;
