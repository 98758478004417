import { IDealState, DealAction } from "../../types";
import { ACCEPT_DEAL, CREATE_DEAL, CREATE_DEAL_ERROR, DEAL_ERROR, CANCEL_DEAL, DECLINE_DEAL, GET_DEAL, GET_DEALS, ACCEPT_SELL_RENEGOTIATED_DEAL, ACCEPT_SELL_RENEGOTIATED_DEAL_ERROR, NEGOTIATE_DEAL, PAY_NOW, GET_PENDING_DEALS, PAY_WITH_PAGA, PAY_WITH_PAYAZA, PAY_WITH_PAGA_ERROR, PAY_WITH_PAYAZA_ERROR } from "./types";


const initialState:IDealState = {
    deal: {},
    deals: [],
    pending_deals: [],
    create_deal: {},
    accept_sell_renegotiated_deal: {},
    accept_sell_renegotiated_deal_error: {},
    create_deal_error: {},
    pay: {},
    pay_with_paga: {},
    pay_with_paga_error: {},
    pay_with_payaza: {},
    pay_with_payaza_error: {},
    negotiate_deal: {},
    decline_deal: {},
    cancel_deal: {},
    loading: true,
    error: null
}



const DealReducer = (state = initialState, action:DealAction) => {
    const { type, payload } = action

    switch (type) {
        case CREATE_DEAL:
        case ACCEPT_DEAL:
            return{
                ...state,
                create_deal: payload,
                create_deal_error: null,
                loading: false
            }
        case ACCEPT_SELL_RENEGOTIATED_DEAL:
            return{
                ...state,
                accept_sell_renegotiated_deal: payload,
                accept_sell_renegotiated_deal_error: null,
                loading: false
            }
        case PAY_NOW:
            return{
                ...state,
                pay:payload,
                loading: false
            }
        case PAY_WITH_PAGA:
            return{
                ...state,
                pay_with_paga:payload,
                loading: false
            }
        case PAY_WITH_PAGA_ERROR:
            return{
                ...state,
                pay_with_paga_error:payload,
                loading: false
            }
        case PAY_WITH_PAYAZA:
            return{
                ...state,
                pay_with_payaza:payload,
                loading: false
            }
        case PAY_WITH_PAYAZA_ERROR:
            return{
                ...state,
                pay_with_payaza_error:payload,
                loading: false
            }
        case GET_DEAL:
            return{
                ...state,
                deal: payload,
                loading: false
            }
        case GET_DEALS:
            return{
                ...state,
                deals: payload,
                loading: false
            }
        case GET_PENDING_DEALS:
            return{
                ...state,
                pending_deals: payload,
                loading: false
            }
        case NEGOTIATE_DEAL:
            return{
                ...state,
                negotiate_deal: payload,
                loading: false
            }
         case DECLINE_DEAL:
            return{
                ...state,
                decline: payload,
                loading: false
            }  
        case CANCEL_DEAL:
            return{
                ...state,
                cancel_deal: payload,
                loading: false
            }
        case CREATE_DEAL_ERROR:
            return{
                ...state,
                create_deal: null,
                create_deal_error: payload,
                loading: false
            } 
        case ACCEPT_SELL_RENEGOTIATED_DEAL_ERROR:
            return{
                ...state,
                accept_sell_renegotiated_deal: null,
                accept_sell_renegotiated_deal_error: payload,
                loading: false
            }
        case DEAL_ERROR:
            return{
                ...state,
                loading: false,
                order: null,
                orders: null,
                error: payload
            } 
        default:
           return state;
    }
}


export default DealReducer