import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Apple from '../../../images/New/apple.svg';
import Android from '../../../images/New/android.svg';
import ArrowRight from '../../../images/New/newArrowRight.svg';
import HeroWeb from '../../../images/New/hero-web.svg';

import useWindowDimensions from '../../WindowDimension/WindowDimension';
import HeroImage from '../../SVGComponents/HeroImage/HeroImage';

type Props = {
  DownloadApp: () => void;
};

const NewHero: React.FC<Props> = ({ DownloadApp }) => {
  const { width } = useWindowDimensions();

  return (
    <>
      <Container>
        <div className="scalex-new-hero__container">
          <Row>
            <Col md="6">
              <div className="scalex-new-hero__container--left-top">
                <h2>
                  Africa's Ultimate
                  <br /> Crypto-Fiat Gateway
                </h2>
                <Button
                  className="scalex-new-hero__container--button"
                  onClick={DownloadApp}
                >
                  <img
                    src={Apple}
                    className="download-image-spacing"
                    alt="apple icon"
                  />
                  <img src={Android} alt="android icon" />
                  <span className="scalex-new-hero__container--line" />
                  Get started
                  <img
                    src={ArrowRight}
                    className="img-spacing"
                    alt="android icon"
                  />
                </Button>
              </div>
              <div className="scalex-new-hero__container__left-bottom">
                <span className="scalex-new-hero__container__left-bottom--line" />
                <p>
                  Leading Africa into cryptocurrency and web3 adoption by
                  building ramp infrastructures to scale businesses, provide
                  secure crypto-fiat transactions and facilitate real-life use
                  cases.
                </p>
              </div>
            </Col>
            <Col md="6">
              <div className="scalex-new-hero__right">
                {width <= 780 ? (
                  <HeroImage />
                ) : (
                  <img src={HeroWeb} alt="hero" />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="scalex-new-hero__container__left-bottom-mobile">
        <span className="scalex-new-hero__container__left-bottom--line" />
        <p>
          Leading Africa into cryptocurrency and web3 adoption by building ramp
          infrastructures to scale businesses, provide secure crypto-fiat
          transactions and facilitate real-life use cases.{' '}
          <span className="new-blue">Learn more</span>
        </p>
      </div>
    </>
  );
};

export default NewHero;
