import React, { Fragment, useState } from 'react';
import Close from '../../../images/New/newClose.svg';
import ChevronRight from '../../../images/New/chevronRight.svg';

interface Props {
  children?: JSX.Element | JSX.Element[];
  title: string;
  id: number;
  setSelectedItem: (v: number) => void;
  selectedItem: number;
}

const NewAccordion: React.FC<Props> = ({
  title,
  children,
  id,
  setSelectedItem,
  selectedItem
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Fragment>
      <div className="scalex-new-accordion--accordion-item">
        {selectedItem === id ? (
          <div
            className={`scalex-new-accordion--accordion-content ${
              id === 1
                ? 'purple-accordion-content '
                : id === 2
                ? 'blue-accordion-content'
                : id === 3 || id === 5
                ? 'brown-accordion-content'
                : 'blue-2-accordion-content'
            }`}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              onClick={() => {
                setIsActive(!isActive);
                setSelectedItem(0);
              }}
            >
              <div className="d-flex align-items-center">
                <h4 className="scalex-new-accordion--accordion-title-2">
                  {title}
                </h4>
              </div>

              {selectedItem === id ? (
                <img src={Close} alt="icon" />
              ) : (
                <img src={ChevronRight} alt="icon" />
              )}
            </div>

            {children}
          </div>
        ) : (
          <div
            className="scalex-new-accordion--accordion-item-wrapper d-flex justify-content-between align-items-center cursor"
            onClick={() => {
              setIsActive(!isActive);
              setSelectedItem(id);
            }}
          >
            <div className="d-flex align-items-center">
              <h4 className="scalex-new-accordion--accordion-title">{title}</h4>
            </div>

            <div>
              {selectedItem === id ? (
                <img src={Close} alt="icon" />
              ) : (
                <img src={ChevronRight} alt="icon" />
              )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default NewAccordion;
