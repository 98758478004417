import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Globe from '../../../images/New/new-globe.svg';
import GlobeMobile from '../../../images/New/globe-mobile.png';

import Token from '../../../images/New/new-token-icons.png';

import useWindowDimensions from '../../WindowDimension/WindowDimension';

type Props = {
  handleContactModal: () => void;
};

const NewSectionTwo: React.FC<Props> = ({ handleContactModal }) => {
  const { width } = useWindowDimensions();

  return (
    <div className="scalex-new-section-two">
      <Container>
        <Row>
          <Col md="6" sm="12" className="scalex-new-section-two--margin">
            <div className="scalex-new-section-two__left h-100">
              <div className="scalex-new-section-two__left--container">
                <h3>Transform how you buy and sell Crypto</h3>
                <p>
                  Trade your digital assets in the blink of an eye. A world of
                  investment opportunities is just a click away with Scalex's
                  simple, easy-to-use interface and advanced technology.
                </p>
              </div>
              <img
                src={Token}
                className="scalex-new-section-two__left--image"
                alt="globe icon"
              />
            </div>
          </Col>
          <Col md="6" sm="12">
            <div className="scalex-new-section-two__right h-100">
              <div className="scalex-new-section-two__right--container">
                <h3>Liquidity-as-a-Service</h3>
                <p>
                  Offer stablecoin deposits and withdrawals directly in your
                  wallets, platforms and apps with our APIs and SDKs.
                </p>
                <Button
                  className="scalex-new-section-two__right--button"
                  onClick={handleContactModal}
                >
                  Contact us
                </Button>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {width <= 1000 ? (
                  <img
                    src={GlobeMobile}
                    className="scalex-new-section-two__right--image"
                    alt="globe icon"
                  />
                ) : (
                  <img
                    src={Globe}
                    className="scalex-new-section-two__right--image"
                    alt="globe icon"
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewSectionTwo;
