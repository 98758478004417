import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import NewHero from '../../../components/NewLandingComponents/NewHero/NewHero';
import NewNavbar from '../../../components/NewLandingComponents/NewNavbar/NewNavbar';
import NewSectionEight from '../../../components/NewLandingComponents/NewSectionEight/NewSectionEight';
import NewSectionFive from '../../../components/NewLandingComponents/NewSectionFive/NewSectionFive';
import NewSectionFour from '../../../components/NewLandingComponents/NewSectionFour/NewSectionFour';
import NewSectionSeven from '../../../components/NewLandingComponents/NewSectionSeven/NewSectionSeven';
import NewSectionSix from '../../../components/NewLandingComponents/NewSectionSix/NewSectionSix';
import NewSectionThree from '../../../components/NewLandingComponents/NewSectionThree/NewSectionThree';
import NewSectionTwo from '../../../components/NewLandingComponents/NewSectionTwo/NewSectionTwo';
import SeoHeader from '../../../components/SeoHeader/SeoHeader';
import { _CONTACT_US } from '../../../redux/auth/authActions';
import { selectContactUs } from '../../../redux/auth/authSelectors';
import { IContactUS } from '../../../types';
import { getOperatingSystem } from '../../../utils/LoginUtils';

type Props = {
  _CONTACT_US: (
    name: string,
    email: string,
    company_name: string,
    message: string,
    setLoading: (v: boolean) => void
  ) => void;
  contactData: IContactUS;
};

const NewHome: React.FC<Props> = ({ _CONTACT_US, contactData }) => {
  const [contactModal, setContactModal] = useState(false);
  const handleContactModal = () => setContactModal(!contactModal);

  const OS = getOperatingSystem(window);

  const DownloadApp = () => {
    if (OS === 'MacOS') {
      return (window.location.href =
        'https://apps.apple.com/ng/app/scalex/id6444333275');
    } else
      return (window.location.href =
        'https://play.google.com/store/apps/details?id=com.scalex.scalex');
  };

  return (
    <>
      <SeoHeader
        title="Scalex - Africa’s Crypto-Fiat Gateway"
        metaTitle="Scalex - Africa’s Crypto-Fiat Gateway"
        description="Scalex is bridging the fiat & crypto economies in Africa and beyond."
        otherTitle="Scalex - Africa’s Crypto-Fiat Gateway"
      />

      <div className="scalex-new-hero">
        <NewNavbar
          handleContactModal={handleContactModal}
          contactModal={contactModal}
          _CONTACT_US={_CONTACT_US}
          contactData={contactData}
        />
        <NewHero DownloadApp={DownloadApp} />
      </div>
      <NewSectionTwo handleContactModal={handleContactModal} />
      <NewSectionThree DownloadApp={DownloadApp} />
      <NewSectionFour
        handleContactModal={handleContactModal}
        DownloadApp={DownloadApp}
      />
      <NewSectionFive />
      <NewSectionSix DownloadApp={DownloadApp} />
      <NewSectionSeven DownloadApp={DownloadApp} />

      <NewSectionEight />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  contactData: selectContactUs
});

const mapDispatchToProps = (dispatch: any) => ({
  _CONTACT_US: (
    name: string,
    email: string,
    company_name: string,
    message: string,
    setLoading: (v: boolean) => void
  ) => dispatch(_CONTACT_US(name, email, company_name, message, setLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewHome);
