import React from 'react'
import { connect } from 'react-redux'

import jwt from 'jsonwebtoken'

import { logoutUser } from '../../redux/auth/authActions';


import { withRouter , RouteComponentProps} from "react-router-dom";

interface Props {
    history:any
    logoutUser:any
}

const VerifyAuth: React.FC<Props & RouteComponentProps> = ({ history, logoutUser }) => {
    history.listen(() => {  // <--- Here you subscribe to the route change

    if(localStorage.jwtToken){

        let token = localStorage.jwtToken;

        token = token.split(' ')[1]
    
        jwt.verify(token, `${process.env.REACT_APP_JWT_SECRET}`, function(err:any, decoded:any) {
            if (err) {
                // Sign out
                logoutUser()

            }
        })
    }
    });
    return <div></div>;
}

const mapDispatchToProps = (dispatch:any) => ({
    logoutUser: () => dispatch(logoutUser())
})

export default connect(null, mapDispatchToProps)(withRouter(VerifyAuth))
