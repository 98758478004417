import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Collapse,
  NavbarToggler,
  Navbar,
  Nav,
  NavItem,
} from 'reactstrap';

// import Logo2 from '../../../images/scalex-logo-final.png'

import Logo from '../../../images/New/dashboard-logo.svg';

interface Props {}

const NavbarComponent: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className="scalex-navbar" light expand="sm">
      <Container>
        <Link to="/" className="navbar-brand">
          {/* <img src={Logo2} alt="Logo" className="logo mr-1"/> */}
          <div className="d-flex align-items-center">
            <img src={Logo} alt="Logo" className="logo mr-1" />
            <span className="scalex-sidebar-left--logo-text mr-3"> Scalex</span>
          </div>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <Link to="/fees" className="nav-link mr-3">
                Fees
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/how-it-works" className="nav-link mr-3">
                How it works
              </Link>
            </NavItem>
            <NavItem>
              <a href="/#api" className="nav-link mr-3">
                Developer
              </a>
            </NavItem>
            <NavItem>
              <a
                href="https://www.learn.scalex.africa"
                className="nav-link mr-3"
              >
                Learn
              </a>
            </NavItem>
          </Nav>
          <div className="d-flex buttons">
            <Link to="/signup" className="nav-link start-trading mr-2">
              Sign Up
            </Link>
            <Link to="/signin" className="nav-link start-trading-outline">
              Log In
            </Link>
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
