import { combineReducers } from "redux";
import AlertReducer from "./alert/alertReducer";
import AuthReducer from "./auth/authReducer";
import DealReducer from "./deal/dealReducer";
import NotificationReducer from "./notification/notificationReducer";
import OrderReducer from "./order/orderReducer";
import UserReducer from "./user/userReducer";
import WalletReducer from "./wallet/walletReducer";
import PriceChartReducer from './prices/pricesReducer'
import CoinbaseReducer from "./coinbase/coinbaseReducer";
import BridgeReducer from "./bridge/bridgeReducer";


const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    order: OrderReducer,
    deal: DealReducer,
    bridge: BridgeReducer,
    wallet: WalletReducer,
    notification: NotificationReducer,
    prices: PriceChartReducer,
    coinbase: CoinbaseReducer,
    alert: AlertReducer
})

export default rootReducer