import React, {useEffect} from 'react';
import { BrowserRouter as Router} from "react-router-dom";

import { Provider } from "react-redux";
import store from './redux/store'

import axios from 'axios'

import jwt from 'jsonwebtoken'
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"
import { ToastContainer } from 'react-toastify';


import { logoutUser, setCurrentUser } from './redux/auth/authActions';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-circular-progressbar/dist/styles.css";
import 'react-toastify/dist/ReactToastify.css';

import 'intro.js/introjs.css';

import './scss/main.scss';


import  {GAListener}  from './components/GAListener/GAListener';
import Routes from './Routes';
import VerifyAuth from './components/VerifyAuth/VerifyAuth';


if(localStorage.jwtToken){
  let token = localStorage.jwtToken;

  setAuthToken(token)

  const decoded:any = jwt_decode(token)

  store.dispatch(setCurrentUser(decoded))

  if(process.env.NODE_ENV === "production"){
    token = token.split(' ')[1]
    
    jwt.verify(token, `${process.env.REACT_APP_JWT_SECRET}`, function(err:any, decoded:any) {

      if (err) {
        // Sign out
        store.dispatch(logoutUser())
      }
      // else{
      //   store.dispatch(setCurrentUser(decoded))
      // }
   });

  }else if(process.env.NODE_ENV === "development"){

    if(localStorage.jwtToken){
    
      const token = localStorage.jwtToken;
    
      setAuthToken(token)
    
      const decoded:any = jwt_decode(token)
    
      store.dispatch(setCurrentUser(decoded))
    
      const currentTime =  1000 < Date.now(); // to get in milliseconds
      
      
      if (decoded.exp < currentTime) {
        // Logout user
      
        store.dispatch(logoutUser())
        // Redirect to login
        window.location.href = "/signin";
      }
    }
  }
    

}



axios.interceptors.response.use((response:any) => {
  return response;
}, (error:any) => {
 if (error.response.status === 401) {
    store.dispatch(logoutUser())
     window.location.reload()
 }

 return Promise.reject(error);
});


function App(): JSX.Element {

  useEffect(() => {
    // refresh after 1 hour = 36000
    const timer = window.setInterval(() => {
      window.location.reload()
      console.log('logging out after 31 mins', Date.now())
    }, 1000 * 60 * 31);
    return () => { 
      window.clearInterval(timer);
    };
  
  }, []);
  

  return (
    <Provider store={store}>  
      <ToastContainer />
      <Router>
        <GAListener trackingId="UA-209085251-1">
          <ScrollToTop>
            <Routes/>
            <VerifyAuth/>
          </ScrollToTop>
        </GAListener>
      </Router>
    </Provider>
  );
}

export default App;
