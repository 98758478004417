import { IUserState, UserAction } from "../../types";
import { ACTIVATE_2FA_ERROR, ADVANCE_INFORMATION, BASIC_INFORMATION, CHANGE_ACCOUNT_PASSWORD, DELETE_BANK, GET_ACCOUNT_NAME, GET_ADVANCE_INFORMATION, ADD_TESTIMONIAL, TESTIMONIAL_ERROR, GET_BANK_LIST, GET_DEPOSIT_HISTORY, GET_ORDER_HISTORY, GET_QR_CODE, GET_QR_CODE_ERROR, GET_SELLER, GET_SMS_OTP, GET_SMS_OTP_ERROR, GET_TOTAL_BALANCE, GET_USER, GET_WITHDRAW_HISTORY, INTERMEDIATE_INFORMATION, REFERRALS, SELLER_ERROR, USER_ERROR, USER_SECURITY, VERIFY_PHONE, GET_TESTIMONIAL } from './types'



const initialState:IUserState = {
    user: {},
    seller: {},
    seller_error: null,
    basic: {},
    intermediate: {},
    deposit_history:[],
    withdraw_history:[],
    order_history: [],
    advance: {},
    security: {},
    get_advance:{},
    get_qr_code_error:{},
    account_name: {},
    add_testimonial: {},
    get_testimonial: [],
    testimonial_error: {},
    get_qr_code:{},
    change_account_password: {},
    delete_bank: {},
    verify_phone: {},
    get_sms_otp:{},
    get_sms_otp_error:{},
    referrals: [],
    total_balance: {},
    bank_list:{},
    loading: false,
    error: null
}

const UserReducer = (state = initialState, action:UserAction) => {
    const { type, payload } = action;

    switch (type) {
        case GET_USER:
           return{
               ...state,
               user: payload,
               loading: true,
                error: null
           } 
        case GET_SELLER:
            return{
                ...state,
                seller: payload,
                loading: true,
                error: null
            }
        case BASIC_INFORMATION:
            return{
                ...state,
                basic: payload,
                loading: true
            }
        case INTERMEDIATE_INFORMATION: 
            return{
                ...state,
                intermediate: payload,
                loading: true
            }
        case ADVANCE_INFORMATION:
            return{
                ...state,
                advance: payload,
                loading: true
            }
        case GET_ADVANCE_INFORMATION:
            return{
                ...state,
                get_advance: payload,
                loading: true
            }
        case VERIFY_PHONE:
            return{
                ...state,
                verify_phone: payload,
                loading: true
            }
        case GET_QR_CODE:
            return{
                ...state,
                get_qr_code:payload,
                loading: true
            }
        case GET_SMS_OTP: 
            return{
                ...state,
                get_sms_otp: payload,
                loading: true
            }
        case GET_SMS_OTP_ERROR: 
            return{
                ...state,
                get_sms_otp_error: payload,
                loading: true
            }
        case ADD_TESTIMONIAL: 
            return{
                ...state,
                add_testimonial: payload,
            }
        case GET_TESTIMONIAL:
            return{
                ...state,
                get_testimonial:payload,
                loading: true
            }
        case TESTIMONIAL_ERROR:
            return{
                ...state,
                testimonial_error: payload,
                loading: true
            }
        case REFERRALS:
            return{
                ...state,
                referrals: payload,
                loading: true
            }
        case GET_ACCOUNT_NAME:
            return{
                ...state,
                account_name: payload,
                loading: true
            }
        case GET_BANK_LIST: 
                return {
                    ...state,
                    bank_list:payload,
                    loading: true
                }
        case GET_DEPOSIT_HISTORY:
            return{
                ...state,
                deposit_history:payload,
                loading: true
            }
        case CHANGE_ACCOUNT_PASSWORD: 
            return{
                ...state,
                change_account_password: payload,
                loading: true
            }
        case USER_SECURITY:
            return{
                ...state,
                security:payload,
                loading: true
            }
        case GET_WITHDRAW_HISTORY:
            return{
                ...state,
                withdraw_history:payload,
                loading: true
            }
        case GET_ORDER_HISTORY:
            return{
                ...state,
                order_history:payload,
                loading: true
            }
        case DELETE_BANK:
            return{
                ...state,
                loading: true,
                delete_bank: payload
            }
        case GET_TOTAL_BALANCE:
            return{
                ...state,
                total_balance: payload,
                loading: true,
                error: null
            }
        case ACTIVATE_2FA_ERROR:
            return{
                ...state,
                get_qr_code_error:payload,
                loading: true
            }
        case SELLER_ERROR:
            return{
                ...state,
                seller_error:payload,
                loading: true
            }
        case GET_QR_CODE_ERROR:
            return{
                ...state,
                get_qr_code_error:payload,
                loading:true
            }
        case USER_ERROR:
            return{
                ...state,
                user: null,
                error: payload
            }
    
        default:
           return state;
    }
}

export default UserReducer