import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  metaTitle: string;
  otherTitle: string;
  description: string;
}

const SeoHeader: React.FC<Props> = ({
  title,
  description,
  metaTitle,
  otherTitle,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={`${metaTitle}`} />
      <meta name="description" content={`${description}`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://scalex.africa" />
      <meta property="og:title" content={`${otherTitle}`} />
      <meta property="og:description" content={`${description}`} />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/https-scalex-africa/image/upload/v1682777425/scalex_gateway3_vxp5bc.jpg"
      />
      <meta property="og:site_name" content="Scalex" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content="https://twitter.com/ScaleX_Africa"
      />
      <meta property="twitter:title" content={`${otherTitle}`} />
      <meta property="twitter:description" content={`${description}`} />
      <meta
        property="twitter:image"
        content="https://res.cloudinary.com/https-scalex-africa/image/upload/v1682777425/scalex_gateway3_vxp5bc.jpg"
      />
    </Helmet>
  );
};

export default SeoHeader;
