import React from 'react'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'
import Footer from '../Footer/Footer'

interface Props {
    children?: JSX.Element|JSX.Element[];
}
const LandingLayout:React.FC<Props> = (props) => {
    return (
        <div>
            <ErrorBoundary>
                {props.children}
                <Footer/>
            </ErrorBoundary>
        </div>
    )
}

export default LandingLayout
