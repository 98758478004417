import jwt_decode from 'jwt-decode';
import { DispatchAuthType } from '../../types';
import Api from '../../utils/Api';
import setAuthToken from '../../utils/setAuthToken';
import axios from 'axios';
import { setMessage } from '../alert/alertActions';
import {
  SIGN_UP,
  CURRENT_USER,
  AUTH_ERROR,
  FORGOT_PASSWORD,
  SIGN_UP_ERROR,
  VERIFY_EMAIL,
  VERIFY_EMAIL_ERROR,
  FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  VERIFY_SIGNIN_OTP_ERROR,
  USER_IP_INFO,
  USER_IP_INFO_ERROR,
  CONTACT_US,
} from './types';

export const UserIpInfo = () => async (dispatch: any) => {
  try {
    const res: any = await axios.get('https://json.geoiplookup.io');

    dispatch({
      type: USER_IP_INFO,
      payload: res.data,
    });
  } catch (err: any) {
    dispatch({
      type: USER_IP_INFO_ERROR,
      payload: err.response,
    });
  }
};
export const Register =
  (
    username: any,
    email: any,
    country: any,
    password: any,
    token: any,
    ip: string,
    city: string,
    region: string,
    country_name: string,
    district: string,
    browser: string,
    OS: string,
    resetForm: any,
    setLoadButton: any
  ) =>
  async (dispatch: any) => {
    const config: any = {
      headers: {
        's-signature': process.env.REACT_APP_API_SECRET,
      },
    };

    try {
      const res: any = await Api.post(
        '/auth/register',
        {
          username,
          email,
          country,
          password,
          token,
          ip,
          city_logged_in_from: city,
          state_logged_in_from: region,
          country_logged_in_from: country_name,
          district_logged_in_from: district,
          browser_logged_in_from: browser,
          os_logged_in_from: OS,
        },
        config
      );
      dispatch({
        type: SIGN_UP,
        payload: res.data.data,
      });
      resetForm();
      dispatch(
        setMessage(
          'Your account has been successfully created. Kindly check your email for verification.',
          'success'
        )
      );
      setLoadButton(false);
    } catch (err: any) {
      const error = err.response.data.message;

      if (error) {
        dispatch(setMessage(error, 'danger'));
      }

      dispatch({
        type: SIGN_UP_ERROR,
        payload: err.response.data,
      });
      setLoadButton(false);
    }
  };

export const VerifyUserEmail =
  (token: string, history: any) => async (dispatch: any) => {
    const config: any = {
      headers: {
        's-signature': process.env.REACT_APP_API_SECRET,
      },
    };

    try {
      const res: any = await Api.post('/auth/verify-email', { token }, config);
      dispatch({
        type: VERIFY_EMAIL,
        payload: res.data.data,
      });

      history.push('/dashboard');

      dispatch(setMessage('Email verified', 'success'));
    } catch (err: any) {
      const error = err.response.data.message;

      if (error) {
        dispatch(setMessage(error, 'danger'));
      }

      dispatch({
        type: VERIFY_EMAIL_ERROR,
        payload: err.response.data,
      });
    }
  };

export const Login =
  (
    email: string,
    password: string,
    ip: string,
    city: string,
    region: string,
    country_name: string,
    district: string,
    browser: string,
    OS: string,
    history: any,
    setLoadButton: any
  ) =>
  async (dispatch: any) => {
    const config: any = {
      headers: {
        's-signature': process.env.REACT_APP_API_SECRET,
      },
    };

    try {
      const res: any = await Api.post(
        `/auth/sign-in`,
        {
          email,
          password,
          ip,
          city_logged_in_from: city,
          state_logged_in_from: region,
          country_logged_in_from: country_name,
          district_logged_in_from: district,
          browser_logged_in_from: browser,
          os_logged_in_from: OS,
        },
        config
      );

      const { two_factor_auth, user_id } = res.data.data;
      if (two_factor_auth) {
        // redirect
        history.push(`/signin-otp/${user_id}`);
      }

      // two_factor_auth  === false
      // regular login
      const { token } = res.data.data;

      if (token) {
        const tokenBearer = 'Bearer ' + token;

        localStorage.setItem('jwtToken', tokenBearer);

        setAuthToken(tokenBearer);

        const decoded: string = jwt_decode(token);

        dispatch(setCurrentUser(decoded));
      }
    } catch (err: any) {
      const error = err.response.data.message;

      if (error) {
        dispatch(setMessage(error, 'danger'));
      }

      dispatch({
        type: AUTH_ERROR,
        payload: err.response.data,
      });

      setLoadButton(false);
    }
  };

export const LoginVerifyOTP =
  (userId: string, otp: string, setLoadButton: any) =>
  async (dispatch: any) => {
    const config: any = {
      headers: {
        's-signature': process.env.REACT_APP_API_SECRET,
      },
    };

    try {
      const res: any = await Api.post(
        `/auth/sign-in/verify`,
        { user_id: userId, otp },
        config
      );

      const { token } = res.data.data;

      const tokenBearer = 'Bearer ' + token;

      localStorage.setItem('jwtToken', tokenBearer);

      setAuthToken(tokenBearer);

      const decoded: string = jwt_decode(token);

      dispatch(setCurrentUser(decoded));
    } catch (err: any) {
      const error = err.response.data.message;

      if (error) {
        dispatch(setMessage(error, 'danger'));
        dispatch(setLoadButton(false));
      }

      dispatch({
        type: VERIFY_SIGNIN_OTP_ERROR,
        payload: err.response.data,
      });
    }
  };

export const ForgetUserPassword =
  (UserCredentials: any, resetForm: any, setLoadButton: any) =>
  async (dispatch: any) => {
    const config: any = {
      headers: {
        's-signature': process.env.REACT_APP_API_SECRET,
      },
    };

    localStorage.setItem('email', UserCredentials.email);

    try {
      const res: any = await Api.post(
        '/auth/forgot-password',
        UserCredentials,
        config
      );
      dispatch({
        type: FORGOT_PASSWORD,
        payload: res.data.message,
      });

      if (res.data.message) {
        setLoadButton(false);
        resetForm();
      }

      dispatch(
        setMessage(
          'Kindly follow the link sent to your email to change your password.',
          'success'
        )
      );
    } catch (err: any) {
      const error = err.response.data.message;

      if (error) {
        dispatch(setMessage(error, 'danger'));
        setLoadButton(false);
      }

      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: err.response,
      });
    }
  };

export const ChangeUserPassword =
  (
    password: any,
    token: any,
    userId: any,
    history: any,
    resetForm: any,
    setLoadButton: any
  ) =>
  async (dispatch: any) => {
    const config: any = {
      headers: {
        's-signature': process.env.REACT_APP_API_SECRET,
      },
    };

    const email = localStorage.getItem('email');

    try {
      const res: any = await Api.post(
        '/auth/update-password',
        { password, email, token, user_id: userId },
        config
      );
      dispatch({
        type: CHANGE_PASSWORD,
        payload: res.data.data,
      });

      resetForm();
      dispatch(setMessage('Password changed.', 'success'));

      if (res.data) {
        localStorage.removeItem('email');
      }

      history.push('/signin');
    } catch (err: any) {
      const error = err.response.data.message;

      if (error) {
        dispatch(setMessage(error, 'danger'));
      }

      dispatch({
        type: AUTH_ERROR,
        payload: err.response.data,
      });

      setLoadButton(false);
    }
  };

export const setCurrentUser = (decoded: any) => {
  return {
    type: CURRENT_USER,
    payload: decoded,
  };
};

export const _CONTACT_US =
  (
    name: string,
    email: string,
    company_name: string,
    message: string,
    setLoading: (v: boolean) => void
  ) =>
  async (dispatch: any) => {
    try {
      const res: any = await axios.post(
        'https://mobile.scalex.africa/extras/contact-us',
        {
          name,
          email,
          company_name,
          message,
        }
      );
      dispatch({
        type: CONTACT_US,
        payload: res.data,
      });

      setLoading(false);
    } catch (err: any) {
      //   dispatch({
      //     type: FORGOT_PASSWORD_ERROR,
      //     payload: err.response.data.message,
      //   });
    }
  };

export const logoutUser = (): any => async (dispatch: DispatchAuthType) => {
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false

  dispatch(setCurrentUser({}));
};
