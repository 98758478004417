export const GET_WALLET = 'GET_WALLET'
export const GET_WALLETS = 'GET_WALLETS'

export const CREATE_ADDRESS_BOOK = 'CREATE_ADDRESS_BOOK'
export const GET_ADDRESS_BOOK = 'GET_ADDRESS_BOOK'

export const ADDRESS_BOOK_ERROR = 'ADDRESS_BOOK_ERROR'

export const WITHDRAWAL_FEE = 'WITHDRAWAL_FEE'
export const WITHDRAWAL_FEE_ERROR = "WITHDRAWAL_FEE_ERROR"

export const DEPOSIT = 'DEPOSIT'
export const WITHDRAW = 'WITHDRAW'
export const WITHDRAW_ERROR = 'WITHDRAW_ERROR'

export const VERIFY_WITHDRAW = 'VERIFY_WITHDRAW'


export const WALLET_ERROR = 'WALLET_ERROR'