import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Logo from '../../../images/New/new-white-logo.svg';

type Props = {};

const NewSectionEight: React.FC<Props> = () => {
  return (
    <div className="scalex-new-section-eight">
      <Container>
        <Row>
          <Col md="5">
            <div className="scalex-new-section-eight__left">
              <img src={Logo} alt="Logo" className="logo mr-1" />
              <p>
                Scalex is leading Africa into cryptocurrency and Web3 adoption
                by building ramp infrastructures to scale businesses and create
                secure crypto-to-fiat transactions for users.
              </p>
            </div>
          </Col>
          <Col md="1"></Col>
          <Col md="6">
            <div className="scalex-new-section-eight__right d-flex justify-content-end">
              <div className="scalex-new-section-eight__right--links">
                <h5>Product</h5>
                <ul>
                  <li>
                    <a href="#download">Download</a>
                  </li>
                  <li>
                    <a href="#testimonial">Testimonials</a>
                  </li>
                </ul>
              </div>
              <div className="scalex-new-section-eight__right--links">
                <h5>Features</h5>
                <ul>
                  <li>
                    <a href="/#products">Bridge</a>
                  </li>
                  <li>
                    <a href="/#products">Scalex P2P</a>
                  </li>
                  <li>
                    <a href="/#products">W3 Ramp</a>
                  </li>
                  <li>
                    <a href="/#products">Scalex APIs and SDKs</a>
                  </li>
                </ul>
              </div>
              <div className="scalex-new-section-eight__right--links">
                <h5>Contact us</h5>
                <ul>
                  <li>
                    <a href="mailto:support@scalex.africa">
                      support@scalex.africa
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/ScalexRamp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/scalexramp/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/scalexramp/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/ScalexRamp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/scalexramp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Telegram
                    </a>
                  </li>
                </ul>
              </div>
              <div className="scalex-new-section-eight__right--links">
                <h5>Legal</h5>
                <ul>
                  <li>
                    <Link to="/terms-and-conditions">Terms of use</Link>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy policy</a>
                  </li>
                  <li>
                    <a href="/data-protection-policy">Data protection policy</a>
                  </li>
                  <li>
                    <a href="/consumer-protection-policy">
                      Consumer protection policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <div className="scalex-new-section-eight--line"></div>
        <p className="scalex-new-section-eight--copyright text-center">
          All rights reserved &copy;{new Date().getFullYear()}{' '}
        </p>
      </Container>
    </div>
  );
};

export default NewSectionEight;
