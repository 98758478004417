import {
  CURRENT_USER,
  USER_LOADING,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_UP,
  SIGN_OUT,
  AUTH_ERROR,
  FORGOT_PASSWORD,
  SIGN_UP_ERROR,
  VERIFY_EMAIL,
  VERIFY_EMAIL_ERROR,
  FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  VERIFY_SIGNIN_OTP,
  VERIFY_SIGNIN_OTP_ERROR,
  USER_IP_INFO,
  USER_IP_INFO_ERROR,
  CONTACT_US,
} from './types';

import { AuthAction, IAuthState } from '../../types';

const isEmpty = require('is-empty');

const initialState: IAuthState = {
  isAuthenticated: false,
  userAuth: {},
  verify_email: {},
  verify_email_error: null,
  signin_otp: {},
  signin_otp_error: null,
  signin_error: null,
  signup: {},
  signup_error: null,
  user_ip_info: {},
  user_ip_info_error: null,
  forgot_password: {},
  forgot_password_error: null,
  contact_us: {},
  loading: false,
  error: null,
};

const AuthReducer = (state = initialState, action: AuthAction) => {
  const { type, payload } = action;
  switch (type) {
    case SIGN_UP:
      return {
        ...state,
        signup: payload,
        loading: true,
        error: null,
      };
    case SIGN_IN:
      return {
        ...state,
        userAuth: payload,
        loading: true,
      };
    case VERIFY_EMAIL:
    case CHANGE_PASSWORD:
      return {
        ...state,
        verify_email: payload,
        loading: true,
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verify_email_error: payload,
        loading: true,
      };
    case VERIFY_SIGNIN_OTP:
      return {
        ...state,
        signin_otp: payload,
        loading: true,
      };
    case VERIFY_SIGNIN_OTP_ERROR:
      return {
        ...state,
        signin_otp_error: payload,
        loading: true,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signup_error: payload,
      };
    case USER_IP_INFO:
      return {
        ...state,
        user_ip_info: payload,
      };
    case USER_IP_INFO_ERROR:
      return {
        ...state,
        user_ip_info_error: payload,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgot_password: payload,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgot_password_error: payload,
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        signin_error: payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        userAuth: null,
        error: null,
      };
    case CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(payload),
        userAuth: payload,
      };
    case CONTACT_US:
      return {
        ...state,
        contact_us: payload,
        loading: true,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
