import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';

import Quote from '../../../images/New/quote.svg';

import { settings, SlickContent } from '../../../utils/carousel';

type Props = {
  DownloadApp: () => void;
};

const NewSectionThree: React.FC<Props> = ({ DownloadApp }) => {
  return (
    <div className="scalex-new-section-three" id="testimonial">
      <Container>
        <Row>
          <Col md="12" sm="12">
            <div className="scalex-new-section-three__left">
              <h3>
                Here’s what our users <br />
                are saying
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="scalex-new-section-three__right h-100">
              <Slider {...settings}>
                {SlickContent.map((slide: any, i: number) => (
                  <div
                    className="scalex-new-section-three__right__wrapper "
                    key={i}
                  >
                    <div className="scalex-new-section-three__right__wrapper--top">
                      <img src={Quote} alt="android icon" />
                      <p>{slide.content}</p>
                    </div>
                    <div className="scalex-new-section-three__right__wrapper--bottom d-flex">
                      <div className="scalex-new-section-three__right__wrapper__avatar">
                        <span>{slide?.name.charAt(0)}</span>
                      </div>
                      <div className="ml-2">
                        <h4>{slide?.name}</h4>
                        <h6>{slide?.role}</h6>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewSectionThree;
