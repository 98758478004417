import { createSelector } from 'reselect';

const selectAuth = (state: any) => state.auth;

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  (auth) => auth.isAuthenticated
);

export const selectUserAuth = createSelector(
  [selectAuth],
  (auth) => auth.userAuth
);

export const selectSignup = createSelector([selectAuth], (auth) => auth.signup);

export const selectContactUs = createSelector(
  [selectAuth],
  (auth) => auth.contact_us
);

export const selectUserIPInfo = createSelector(
  [selectAuth],
  (auth) => auth.user_ip_info
);

export const selectSignInOTP = createSelector(
  [selectAuth],
  (auth) => auth.signin_otp
);

export const selectSignInOTPError = createSelector(
  [selectAuth],
  (auth) => auth.signin_otp_error
);

export const selectLoading = createSelector(
  [selectAuth],
  (auth) => auth.loading
);

export const selectAuthError = createSelector(
  [selectAuth],
  (auth) => auth.error
);
