export const history = [
  {
    id: 1,
    coin_type: 'BTC',
    address: 'jbsidskbfeifkbei;fknckvnd',
    amount: '522,3434,535',
    date: '12 Dec. 2020',
    txnid: 'YYYTTriuo9498474',
    status: 'Completed'
  },
  {
    id: 2,
    coin_type: 'BTC',
    address: 'jbsidskbfeifkbei;fknckvnd',
    amount: '522,3434,535',
    date: '12 Dec. 2020',
    txnid: 'YYYTTriuo9498474',
    status: 'Completed'
  },
  {
    id: 3,
    coin_type: 'BTC',
    address: 'jbsidskbfeifkbei;fknckvnd',
    amount: '522,3434,535',
    date: '12 Dec. 2020',
    txnid: 'YYYTTriuo9498474',
    status: 'Completed'
  }
];

export const ordersData = [
  {
    id: 1,
    crypt: 'BTC',
    volume: '0.000422',
    rate: '520',
    offer_type: 'BUY',
    deals: [
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      },
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      },
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      }
    ]
  },
  {
    id: 2,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: [
      {
        user: 'Taiwo Adeleke',
        amount: '590',
        volume: '0.10340'
      }
    ]
  },
  {
    id: 3,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 4,
    crypt: 'ETH',
    volume: '0.000422',
    rate: '520',
    offer_type: 'BUY',
    deals: [
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      },
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      },
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      }
    ]
  },
  {
    id: 5,
    crypt: 'BNB',
    volume: '0.000422',
    rate: '520',
    offer_type: 'BUY',
    deals: [
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      },
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      },
      {
        user: 'John Doe',
        amount: '510',
        volume: '0.00000034'
      }
    ]
  },
  {
    id: 6,
    crypt: 'TRC(ERC)',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 7,
    crypt: 'USDT',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 8,
    crypt: 'NGNT',
    volume: '0.23422',
    rate: '500',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 9,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 10,
    crypt: 'NGNT',
    volume: '0.23422',
    rate: '500',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 11,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 12,
    crypt: 'NGNT',
    volume: '0.23422',
    rate: '500',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 13,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 14,
    crypt: 'NGNT',
    volume: '0.23422',
    rate: '500',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 15,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 16,
    crypt: 'NGNT',
    volume: '0.23422',
    rate: '500',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 17,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 18,
    crypt: 'NGNT',
    volume: '0.23422',
    rate: '500',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 19,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  },
  {
    id: 20,
    crypt: 'TRC',
    volume: '0.23422',
    rate: '520',
    offer_type: 'BUY',
    deals: []
  }
];

export const selectCoin = [
  {
    id: 1,
    value: 'All'
  },
  {
    id: 2,
    value: 'ETH'
  },
  {
    id: 3,
    value: 'BTC'
  },
  {
    id: 4,
    value: 'USDT'
  }
  // {
  //     id: 5,
  //     value: 'BNB'
  // },
  // {
  //     id: 6,
  //     value: 'BUSD'
  // },
  // {
  //     id: 7,
  //     value: 'NGNT'
  // }
];

export const selectCountry = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Democratic People's Republic of Korea", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kosovo', code: 'XK' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People's Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];

export const referrals = [
  {
    id: 1,
    name: 'Lagbaja Temedo',
    commission: '10%',
    earning: '2, 000',
    profile_status: 'verified'
  },
  {
    id: 2,
    name: 'John Dow',
    commission: '10%',
    earning: '6, 000',
    profile_status: 'verified'
  }
];

export const networkType = (coin_type: any) => {
  if (coin_type.toUpperCase() === 'USDT_ERC_20') {
    return 'ERC20';
  } else if (coin_type.toUpperCase() === 'ETH_BEP_20') {
    return 'BEP20';
  } else if (
    coin_type.toUpperCase() === 'USDT_BEP_20' ||
    coin_type.toUpperCase() === 'USDT_BEP20'
  ) {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'BTC_BEP_20') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'BUSD') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'BNB') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'USDT_TRC_20') {
    return 'TRC20';
  } else if (coin_type.toUpperCase() === 'NGNT') {
    return 'ERC20';
  } else if (coin_type.toUpperCase() === 'ETH') {
    return 'ERC20';
  } else if (coin_type.toUpperCase() === 'LTC') {
    return 'LTC';
  } else if (coin_type.toUpperCase() === 'BTC') {
    return 'BTC';
  } else {
    return coin_type.toUpperCase();
  }
};

export const coinType = (coin_type: any) => {
  if (coin_type.toUpperCase() === 'ETH') {
    return 'ETH';
  } else if (coin_type.toUpperCase() === 'ETH_BEP_20') {
    return 'ETH';
  } else if (
    coin_type.toUpperCase() === 'USDT_BEP_20' ||
    coin_type.toUpperCase() === 'USDT_BEP20'
  ) {
    return 'USDT';
  } else if (coin_type.toUpperCase() === 'USDT_ERC_20') {
    return 'USDT';
  } else if (coin_type.toUpperCase() === 'USDT_TRC_20') {
    return 'USDT';
  } else if (coin_type.toUpperCase() === 'BTC_BEP_20') {
    return 'BTC';
  } else if (coin_type.toUpperCase() === 'BTC') {
    return 'BTC';
  } else if (coin_type.toUpperCase() === 'LTC') {
    return 'LTC';
  } else {
    return coin_type.toUpperCase();
  }
};

export const MyOrderCoinType = (coin_type: any) => {
  if (coin_type.toUpperCase() === 'ETH') {
    return 'ETH';
  } else if (coin_type.toUpperCase() === 'ETH_BEP_20') {
    return 'ETH';
  } else if (coin_type.toUpperCase() === 'USDT_BEP_20') {
    return 'USDT';
  } else if (coin_type.toUpperCase() === 'USDT_ERC_20') {
    return 'USDT';
  } else if (coin_type.toUpperCase() === 'USDT_TRC_20') {
    return 'USDT';
  } else if (coin_type.toUpperCase() === 'BTC_BEP_20') {
    return 'BTC';
  } else if (coin_type.toUpperCase() === 'BTC') {
    return 'BTC';
  } else if (coin_type.toUpperCase() === 'LTC') {
    return 'LTC';
  } else {
    return coin_type.toUpperCase();
  }
};

export const MyOrderNetworkType = (coin_type: any) => {
  if (coin_type.toUpperCase() === 'USDT_ERC_20') {
    return 'ERC20';
  } else if (coin_type.toUpperCase() === 'ETH_BEP_20') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'USDT_BEP_20') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'BTC_BEP_20') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'BUSD') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'BNB') {
    return 'BEP20';
  } else if (coin_type.toUpperCase() === 'USDT_TRC_20') {
    return 'TRC20';
  } else if (coin_type.toUpperCase() === 'NGNT') {
    return 'ERC20';
  } else if (coin_type.toUpperCase() === 'ETH') {
    return 'ERC20';
  } else if (coin_type.toUpperCase() === 'LTC') {
    return 'LTC';
  } else if (coin_type.toUpperCase() === 'BTC') {
    return 'BTC';
  } else {
    return null;
  }
};

export const token = {
  btc: 'btc',
  ltc: 'ltc',
  doge: 'doge',
  eth: 'eth',
  usdt_erc_20: 'usdt_erc_20',
  ngnt: 'ngnt',
  bnb: 'bnb',
  usdt_bep20: 'usdt_bep_20',
  eth_bep20: 'eth_bep_20',
  busd: 'busd',
  btc_bep20: 'btc_bep_20',
  usdt_trc: 'usdt_trc_20'
};

export const exchange = [
  {
    id: 1,
    name: 'Coinbase',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636492722/image_23.png'
  },
  {
    id: 2,
    name: 'Binance',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636492847/image_23_1.png'
  },
  {
    id: 3,
    name: 'Kraken',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636492873/326609.png'
  },
  {
    id: 4,
    name: 'Bitfinex',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636492909/image_23_2.png'
  },
  {
    id: 5,
    name: 'FTX',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636529765/ftx.png'
  },
  {
    id: 6,
    name: 'Poloniex',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636493001/image_24.png'
  },
  {
    id: 7,
    name: 'Kucoin',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636493021/image_24_1.png'
  },
  {
    id: 8,
    name: 'Huobi',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636493045/image_23_4.png'
  },
  {
    id: 9,
    name: 'Bitstamp',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636493132/image_23_5.png'
  },
  {
    id: 10,
    name: 'Bittrex',
    token: 'USDT',
    network: 'trc20',
    image:
      'https://res.cloudinary.com/trapintrovert/image/upload/v1636493160/image_23_6.png'
  }
];

export const transactions: any = [
  {
    id: 1,
    exchange_name: 'Binance',
    status: 'completed',
    amount: '0.3554',
    amount_in_naira: '522.02',
    time: '03:02 PM',
    date: 'Dec 20 2020'
  },
  {
    id: 2,
    exchange_name: 'FTX',
    status: 'failed',
    amount: '1.3554',
    amount_in_naira: '522.02',
    time: '03:02 PM',
    date: 'Dec 20 2020'
  }
];

export const testimonial: any = [
  {
    id: 1,
    name: 'John Doe',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,',
    approve: true
  },
  {
    id: 2,
    name: 'Esther Kabira',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,',
    approve: true
  },
  {
    id: 3,
    name: 'Jamiu Yusuf',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,',
    approve: false
  },
  {
    id: 4,
    name: 'Rachel Lane',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,',
    approve: true
  },
  {
    id: 5,
    name: 'Kat Union',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,',
    approve: true
  },
  {
    id: 6,
    name: 'Jack Taiwo',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,',
    approve: true
  }
];

export const ExpectedAmount: any = (amount: any) => {
  if (Number(amount) < 5000) {
    return 1;
  } else if (Number(amount) >= 5000 && Number(amount) < 10000) {
    return 2.5;
  } else if (Number(amount) >= 10000) {
    return 5;
  } else {
    return 0;
  }
};

export const AccordionData: any = [
  {
    id: 1,
    title: 'Bridge',
    content:
      'Seamlessly buy and sell your crypto assets as well as make mainstream crypto transactions to and from crypto platforms easier with just one click.'
  },
  // {
  //   id: 2,
  //   title: 'Scalex P2P',
  //   content:
  //     'If money can change hands, so does crypto. We have automated the process to make it safer, faster, and more secure.'
  // },
  {
    id: 3,
    title: 'W3 Ramp',
    content:
      'Africa can now easily scale into the GameFi, NFTs, Metaverse, and DeFi protocols on the Web3 ecosystem. Maken swaps across multiple chains, fund your Web3 wallets directly with fiat and withdraw directly into your bank account with ease.'
  },
  {
    id: 4,
    title: 'Scalex APIs and SDKs',
    content:
      'Africa can now easily scale into GameFi, NFTs, Metaverse, and DeFi protocols in the Web3 ecosystem. Make swaps across multiple chains, fund your Web3 wallets directly with fiat, and withdraw directly into your bank account with ease.'
  },
  {
    id: 5,
    title: 'OTC DESK',
    content: 'Trade large volume tickets at the best rates in the market.'
  }
];
