import { Route, Switch } from 'react-router-dom';
import NotFoundPage from './pages/Landing/404Page/NotFoundPage';
import Home from './pages/Landing/Home/Home';
import Terms from './pages/Landing/Terms/Terms';
import PrivacyPolicy from './pages/Landing/PrivacyPolicy/PrivacyPolicy';
import DataProtectPolicy from './pages/Landing/DataProtectionPolicy/DataProtectionPolicy';
import ConsumerProtectionPolicy from './pages/Landing/ConsumerProtectionPolicy/ConsumerProtectionPolicy';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />

      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route
        exact
        path="/data-protection-policy"
        component={DataProtectPolicy}
      />
      <Route
        exact
        path="/consumer-protection-policy"
        component={ConsumerProtectionPolicy}
      />

      <Route exact path="/terms-and-conditions" component={Terms} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
