import { AlertAction } from '../../types';
import { SET_ALERT, REMOVE_ALERT} from './types'

const initialState:any = [];

const AlertReducer =  (state = initialState, action: AlertAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return [...state, payload];
    case REMOVE_ALERT:
      return state.filter((alert:any) => alert.id !== payload);
    default:
      return state;
  }
}

export default AlertReducer