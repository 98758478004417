import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import LandingLayout from '../../../components/LandingComponent/LandingLayout/LandingLayout';
import NavbarComponent from '../../../components/LandingComponent/NavbarComponent/NavbarComponent';

interface Props {}

const NotFoundPage = (props: Props) => {
  return (
    <>
      <NavbarComponent />
      <LandingLayout>
        <section className="scalex-404-page">
          <Container>
            <Row>
              <Col>
                <div>
                  <h1>404 not found</h1>
                  <p>What you're looking for isn't here</p>
                  <Button color="primary">
                    <Link to="/">Go back to homepage</Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </LandingLayout>
    </>
  );
};

export default NotFoundPage;
