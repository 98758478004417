import React, { Component, ErrorInfo, Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Navbar, Nav} from "reactstrap";
import Footer from "../LandingComponent/Footer/Footer";

import Logo from '../../images/New/dashboard-logo.svg'

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          <Navbar className="scalex-navbar" light expand="sm">
            <Container>
                <Link to="/" className="navbar-brand">
                <div className="d-flex align-items-center">
                    <img src={Logo} alt="Logo" className="logo mr-1"/><span className="scalex-sidebar-left--logo-text mr-3"> Scalex</span>
                </div>
                
                </Link>                
                <Nav className="mr-auto" navbar> 
                </Nav>
            </Container>
          </Navbar>
          <section className="scalex-error-boundary">
            <Container className="scalex-error-boundary">
                <Row>
                    <Col>
                    <div className="d-flex align-items-center">
                      <div className="mt-5">
                        <h1>Sorry.. there was an error</h1>
                        <Button color="primary"><Link to="/dashboard" className="text-white"> Back to dashboard</Link></Button>
                      </div>
                    </div>
                    </Col>
                </Row>
            </Container>
          </section>
          <Footer/>
        </Fragment>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;