// User types
export const CURRENT_USER = 'CURRENT_USER';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';

export const VERIFY_SIGNIN_OTP = 'VERIFY_SIGNIN_OTP';
export const VERIFY_SIGNIN_OTP_ERROR = 'VERIFY_SIGNIN_OTP_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const USER_IP_INFO = 'USER_IP_INFO';
export const USER_IP_INFO_ERROR = 'USER_IP_INFO_ERROR';

export const SIGN_OUT = 'SIGN_OUT';

export const USER_LOADING = 'USER_LOADING';

export const AUTH_ERROR = 'AUTH_ERROR';

export const CONTACT_US = 'CONTACT_US';
