export const COINBASE_BTC_DATA = 'COINBASE_BTC_DATA'

export const COINBASE_ETH_DATA = 'COINBASE_ETH_DATA'

export const COINBASE_BNB_DATA = 'COINBASE_BNB_DATA'

export const COINBASE_USDT_DATA = 'COINBASE_USDT_DATA'

export const COINBASE_USDC_DATA = 'COINBASE_USDC_DATA'

export const COINBASE_BUSD_DATA = 'COINBASE_BUSD_DATA'

export const COINBASE_ERROR = 'COINBASE_PRICE_ERROR'
