import React from 'react';

import { Col, Container, Row } from 'reactstrap';

type Props = {
  DownloadApp: () => void;
};

const NewSectionSeven: React.FC<Props> = ({ DownloadApp }) => {
  return (
    <div className="scalex-new-section-seven">
      <Container>
        <Row>
          <Col md="3"></Col>
          <Col>
            <div className="scalex-new-section-seven__wrapper">
              <div className="scalex-new-section-seven__wrapper--heading ">
                <h5>OLD USERS ONLY</h5>
              </div>
              <h4>Looking for the old Scalex?</h4>
              <p>
                We are currently migrating our users to the app. In the
                meantime, can download the app with improved features.
              </p>
              {/* <Button
                className="scalex-custom-small-button"
                onClick={DownloadApp}
              >
                Download
              </Button> */}
            </div>
          </Col>
          <Col md="3"></Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewSectionSeven;
