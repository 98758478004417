import axios from 'axios';

const Api = axios.create({
  baseURL: 'https://code.scalex.africa/w/api/v1',
});

export default Api;

// const Api = axios.create({
//   baseURL: 'https://scalex-bot.herokuapp.com/w/api/v1',
// })

// export default Api
