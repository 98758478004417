import { IOrderState, OrderAction } from "../../types";
import { 
    CREATE_ORDER, 
    GET_ORDER, 
    GET_BUY_ORDERS, 
    GET_SELL_ORDERS, 
    ORDER_ERROR, 
    GET_MARKET_PRICE, 
    GET_BUY_ORDERS_BY_USER_ID, 
    GET_SELL_ORDERS_BY_USER_ID,
    UPDATE_ORDER,
    DELETE_ORDER,
    CREATE_ORDER_ERROR,
    GET_PUBLIC_BUY_ORDER,
    GET_PUBLIC_SELL_ORDER,
    GET_PUBLIC_ORDER
} from "./types";

const initialState:IOrderState = {
    order:{},
    public_order: {},
    create_order_error:null,
    edit_order:{},
    public_buy_order: [],
    public_sell_order:[],
    buy_orders: [],
    buy_orders_by_user_id: [],
    sell_orders: [],
    sell_orders_by_user_id: [],
    market_price: {},
    loading: false,
    error: null
}

const OrderReducer = (state = initialState, action:OrderAction) => {
    const { type, payload } = action;

    switch (type) {
        case CREATE_ORDER:
            return{
                ...state,
                order: payload,
                loading: true,
                error: null
            }
        case UPDATE_ORDER:
            return{
                ...state,
                edit_order: payload,
                loading: true
            }
        case GET_ORDER:
        case DELETE_ORDER:
            return{
                ...state,
                order: payload,
                loading: true
            }
        case GET_PUBLIC_ORDER:
            return{
                ...state,
                public_order:payload,
                loading: true
            }
        case GET_BUY_ORDERS: 
            return{
                ...state,
                buy_orders: payload,
                loading: true
            } 
        case GET_BUY_ORDERS_BY_USER_ID: 
            return{
                ...state,
                buy_orders_by_user_id: payload,
                loading: true
            } 
        case GET_SELL_ORDERS_BY_USER_ID: 
            return{
                ...state,
                sell_orders_by_user_id: payload,
                loading: true
            } 
        case GET_SELL_ORDERS: 
            return{
                ...state,
                sell_orders: payload,
                loading: true
            }
        case GET_PUBLIC_BUY_ORDER:
            return{
                ...state,
                public_buy_order:payload,
                loading: true
            }
        case GET_PUBLIC_SELL_ORDER:
            return{
                ...state,
                public_sell_order:payload,
                loading: true
            }
        case GET_MARKET_PRICE: 
            return{
                ...state,
                market_price: payload,
                loading: true
            }
        case ORDER_ERROR:
            return{
                ...state,
                order: null,
                orders: null,
                loading: true,
                error: payload
            }
        case CREATE_ORDER_ERROR:
            return{
                ...state,
                order: null,
                orders: null,
                loading: true,
                create_order_error: payload
            }
        default:
            return state;
    }
}

export default OrderReducer