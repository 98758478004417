export const BTC_PRICE_CHART = "BTC_PRICE_CHART"
export const BTC_DATA = 'BTC_DATA'

export const ETH_PRICE_CHART = "ETH_PRICE_CHART"
export const ETH_DATA = 'ETH_DATA'

export const BNB_PRICE_CHART = "BNB_PRICE_CHART"
export const BNB_DATA = 'BNB_DATA'

export const USDT_PRICE_CHART = "USDT_PRICE_CHART"
export const USDT_DATA = 'USDT_DATA'

export const USDC_PRICE_CHART = "USDC_PRICE_CHART"
export const USDC_DATA = 'USDC_DATA'

export const BUSD_PRICE_CHART = "BUSD_PRICE_CHART"
export const BUSD_DATA = 'BUSD_DATA'

export const PRICE_CHART_LOADING = 'PRICE_CHART_LOADING'

export const PRICE_CHART_ERROR = 'PRICE_CHART_ERROR'