import React, { useState } from 'react';
import { Button, Container } from 'reactstrap';
import Logo2 from '../../../images/scalex-logo-new.svg';
import Error from '../../../images/New/error-alert.svg';
import CloseModal from '../../../images/New/close-modal.svg';
import ContactArrow from '../../../images/New/contact-arrow.svg';

import ModalLayout from '../ModalLayout/ModalLayout';
import { Formik, Form, Field } from 'formik';
import { string, object } from 'yup';
import { Link } from 'react-router-dom';
import Menu from '../../SVGComponents/Menu/Menu';
import { IContactUS } from '../../../types';

interface MyFormValues {
  name: string;
  email: string;
  company: string;
  message: string;
}

const initialValues: MyFormValues = {
  name: '',
  email: '',
  company: '',
  message: ''
};

type Props = {
  handleContactModal: () => void;

  _CONTACT_US: (
    name: string,
    email: string,
    company_name: string,
    message: string,
    setLoading: (v: boolean) => void
  ) => void;

  contactModal: boolean;
  contactData: IContactUS;
};

const NewNavbar: React.FC<Props> = ({
  contactModal,
  _CONTACT_US,
  contactData,
  handleContactModal
}) => {
  const [aboutModal, setAboutModal] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [loadButton, setLoadButton]: [boolean, (loadButton: boolean) => void] =
    useState<boolean>(false);

  const handleAboutModal = () => setAboutModal(!aboutModal);
  const handleMenuModal = () => setMenuModal(!menuModal);

  const myForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values: MyFormValues, { resetForm }) => {
          const { name, email, company, message } = values;

          _CONTACT_US(name, email, company, message, setLoadButton);
          setUserEmail(email);
          setLoadButton(true);
        }}
        validationSchema={object().shape({
          name: string().required('Address is required'),
          email: string()
            .email('Email not valid')
            .required('Email is required.'),
          company: string().required('Company is required'),
          message: string().required('Message is required')
        })}
      >
        {({
          errors,
          handleSubmit,
          isValid,
          touched,
          values: { name, email, company, message }
        }) => (
          <Form>
            <div className="new-group">
              <Field
                name="name"
                placeholder="Full name"
                type="text"
                className={`${
                  touched.name && errors.name ? 'error-input' : ''
                } form-input `}
              />
              {touched.name && errors.name ? (
                <div className="d-flex aligns-item-center mt-2">
                  <img src={Error} alt="error icon" />
                  <div className="error-message ml-2 mt-1"> {errors.name}</div>
                </div>
              ) : null}
            </div>

            <div className="new-group">
              <Field
                name="email"
                placeholder="Email address"
                type="text"
                className={`${
                  touched.email && errors.email ? 'error-input' : ''
                } form-input `}
              />
              {touched.email && errors.email ? (
                <div className="d-flex aligns-item-center mt-2">
                  <img src={Error} alt="error icon" />
                  <div className="error-message ml-2 mt-1"> {errors.email}</div>
                </div>
              ) : null}
            </div>

            <div className="new-group">
              <Field
                name="company"
                placeholder="Company name"
                type="text"
                className={`${
                  touched.company && errors.company ? 'error-input' : ''
                } form-input `}
              />
              {touched.company && errors.company ? (
                <div className="d-flex aligns-item-center mt-2">
                  <img src={Error} alt="error icon" />
                  <div className="error-message ml-2 mt-1">
                    {errors.company}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="new-group">
              <Field
                name="message"
                placeholder="Your message"
                as="textarea"
                rows="5"
                className={`${
                  touched.message && errors.message ? 'error-input' : ''
                } form-input `}
              />
              {touched.message && errors.message ? (
                <div className="d-flex aligns-item-center mt-2">
                  <img src={Error} alt="error icon" />
                  <div className="error-message ml-2 mt-1">
                    {errors.message}
                  </div>
                </div>
              ) : null}
            </div>
            {loadButton || !name || !email || !company || !message ? (
              <Button
                type="submit"
                className="btn-block scalex-new-modal__right-content--button "
                disabled={true}
              >
                Send message
              </Button>
            ) : (
              <Button
                type="submit"
                className={`btn-block scalex-new-modal__right-content--button ${
                  isValid ? '' : 'scalex-new-modal__right-content--disabled '
                }`}
                disabled={!isValid}
              >
                Send message
              </Button>
            )}
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Container>
      <div className="scalex-new-navbar">
        <div>
          <Link to="/">
            <img src={Logo2} alt="scalex-logo" />
          </Link>
        </div>
        <div className="d-flex scalex-new-navbar__nav-list">
          <ul className="d-flex">
            <li>
              <a href="/#products">Products</a>
            </li>
            <li>
              <a href="#." onClick={handleAboutModal}>
                About Scalex
              </a>
            </li>
            <li>
              <a href="#." onClick={handleContactModal}>
                Contact us
              </a>
            </li>
          </ul>
          {/* <Link to="/signin">
            <Button className="scalex-new-navbar--button">Old user?</Button>
          </Link> */}
        </div>
        <div className="scalex-new-navbar__menu" onClick={handleMenuModal}>
          <Menu className="scalex-new-navbar__menu--open" />
        </div>
      </div>
      <ModalLayout modal={aboutModal} toggle={handleAboutModal}>
        <div className="scalex-new-modal__right-content">
          <div className="scalex-new-modal__right-content__middle">
            <h3>ABOUT SCALEX</h3>

            <p>
              Our Simple Story Scalex was born out of a simple idea—to create a
              community where people can trade crypto without fear of getting
              scammed. Our founders were once victims of online fraud, and we
              wanted to do something about it. We built a platform that fosters
              trust and provides a secure environment for people to trade
              peer-to-peer crypto in real-time. We then realized that our idea
              could scale beyond providing a safe trade place. We wanted to make
              it easy and comfortable for everyone to trade crypto. And that's
              what we're all about.
            </p>

            <p>
              At Scalex, we're on a mission to empower individuals and
              businesses worldwide to take control of their financial future
              through digital currency. We believe digital currencies will
              revolutionize the economic landscape, so we have invented a new
              way to interact with your crypto assets. By integrating our APIs
              and SDKs, developers are joining us to rewrite the future of
              crypto in Africa.
            </p>
            <p>
              The Scalex app is a user-friendly platform for buying and selling
              cryptocurrencies backed by a team of experts dedicated to
              delivering exceptional customer service. Our platform allows you
              to buy crypto from your bank or withdraw to your bank in just 3
              minutes.
            </p>
          </div>
        </div>
      </ModalLayout>
      <ModalLayout modal={contactModal} toggle={handleContactModal}>
        <div className="scalex-new-modal__right-content right-content-margin-option-2">
          <div className="scalex-new-modal__right-content__middle-2">
            <h3 className="middle-content-title-spacing-option-2">
              CONTACT US
            </h3>
            {contactData.status === true ? (
              <div className="scalex-new-modal__right-content__success-message">
                <img src={ContactArrow} alt="icon" />
                <h4>Your report has been sent</h4>
                <p>
                  Follow up this inquiry via your email <br />
                  {userEmail && userEmail}
                </p>
                <div
                  className="scalex-new-modal__right-content__success-message--close"
                  onClick={handleContactModal}
                >
                  <h6>Close window</h6>
                </div>
              </div>
            ) : (
              <div>{myForm()}</div>
            )}
            <div className="scalex-new-modal__right-content--line-text">
              <h4>
                <span>OR</span>
              </h4>
            </div>
            <div className="scalex-new-modal__right-content__contact-info">
              <h4>CONNECT WITH SCALEX</h4>
              <div>
                <p>Email: support@scalex.africa</p>
              </div>
              <div className="scalex-new-modal__right-content__contact-info--line" />
              <div className="scalex-new-modal__right-content__contact-info--links">
                <ul>
                  <li>
                    <a
                      href="https://t.me/scalexramp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Telegram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/ScalexRamp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/scalexramp/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ModalLayout>
      <div
        className={`scalex-mobile-menu ${
          menuModal ? 'open-menu-modal' : 'close-menu-modal'
        }`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center scalex-mobile-menu--top">
            <div className="scalex-mobile-menu--dot"></div>
            <h6>MENU ITEMS</h6>
          </div>
          <div onClick={handleMenuModal}>
            <img src={CloseModal} alt="about" />
          </div>
        </div>
        <div className="scalex-mobile-menu--line"></div>
        <div className="scalex-mobile-menu__links">
          <ol>
            <li>
              <a href="/#products" onClick={handleMenuModal}>
                Products
              </a>
            </li>
            <li>
              <a href="#." onClick={handleAboutModal}>
                About Scalex
              </a>
            </li>
            <li>
              <a href="#." onClick={handleContactModal}>
                Contact us
              </a>
            </li>
          </ol>
        </div>
        <div className="scalex-mobile-menu--line"></div>

        <div className="d-flex justify-content-between align-items-center mt-5">
          <Link to="/signin">
            <Button className="scalex-mobile-menu--button">Old user?</Button>
          </Link>
          <Button
            className="scalex-mobile-menu--button-close"
            onClick={handleMenuModal}
          >
            Close menu
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default NewNavbar;
