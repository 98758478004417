import { IPriceState, PricesAction } from '../../types';
import {
  BTC_DATA,
  BTC_PRICE_CHART,
  ETH_DATA,
  ETH_PRICE_CHART,
  BNB_DATA,
  BNB_PRICE_CHART,
  PRICE_CHART_ERROR,
  USDT_DATA,
  USDT_PRICE_CHART,
  USDC_DATA,
  USDC_PRICE_CHART,
  BUSD_PRICE_CHART,
  BUSD_DATA,
} from './types';

const initialState: IPriceState = {
  btc_price_chart: [],
  bnb_price_chart: [],
  eth_price_chart: [],
  usdt_price_chart: [],
  usdc_price_chart: [],
  busd_price_chart: [],
  busd_data: {},
  usdc_data: {},
  usdt_data: {},
  btc_data: {},
  eth_data: {},
  bnb_data: {},
  loading: false,
  error: null,
};

const PriceChartReducer = (state = initialState, action: PricesAction) => {
  const { type, payload } = action;

  switch (type) {
    case BTC_PRICE_CHART:
      return {
        ...state,
        btc_price_chart: payload,
        loading: true,
      };
    case BTC_DATA:
      return {
        ...state,
        btc_data: payload,
        loading: true,
      };
    case ETH_PRICE_CHART:
      return {
        ...state,
        eth_price_chart: payload,
        loading: true,
      };
    case ETH_DATA:
      return {
        ...state,
        eth_data: payload,
        loading: true,
      };
    case BNB_PRICE_CHART:
      return {
        ...state,
        bnb_price_chart: payload,
        loading: true,
      };
    case BNB_DATA:
      return {
        ...state,
        bnb_data: payload,
        loading: true,
      };
    case USDT_PRICE_CHART:
      return {
        ...state,
        usdt_price_chart: payload,
        loading: true,
      };
    case USDT_DATA:
      return {
        ...state,
        usdt_data: payload,
        loading: true,
      };
    case USDC_PRICE_CHART:
      return {
        ...state,
        usdc_price_chart: payload,
        loading: true,
      };
    case USDC_DATA:
      return {
        ...state,
        usdc_data: payload,
        loading: true,
      };
    case BUSD_PRICE_CHART:
      return {
        ...state,
        busd_price_chart: payload,
        loading: true,
      };
    case BUSD_DATA:
      return {
        ...state,
        busd_data: payload,
        loading: true,
      };
    case PRICE_CHART_ERROR:
      return {
        ...state,
        btc_price_chart: null,
        loading: true,
        error: payload,
      };
    default:
      return state;
  }
};

export default PriceChartReducer;
