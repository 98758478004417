import { IWalletState, WalletAction } from "../../types";
import { ADDRESS_BOOK_ERROR, CREATE_ADDRESS_BOOK, DEPOSIT, GET_ADDRESS_BOOK, GET_WALLET, GET_WALLETS, VERIFY_WITHDRAW, WALLET_ERROR, WITHDRAW, WITHDRAWAL_FEE, WITHDRAWAL_FEE_ERROR, WITHDRAW_ERROR } from "./types";


const initialState:IWalletState = {
    wallet: {},
    wallets: [],
    deposit: {},
    verify_withdraw:{},
    withdraw: {},
    withdrawal_fee: {},
    withdrawal_fee_error: null,
    withdrawal_error: {},
    address_book_error: {},
    address_book: [],
    create_address_book: {},
    loading: true,
    error: null
}

const WalletReducer = (state = initialState, action:WalletAction) => {
    const { type, payload} = action;

    switch (type) {
        case GET_WALLET:
            return{
                ...state,
                wallet: payload,
                loading: false,
            }
        case GET_WALLETS:
            return{
                ...state,
                wallets: payload,
                loading: false,
            }
        case CREATE_ADDRESS_BOOK: 
            return{
                ...state,
                create_address_book: payload,
                loading: false,
            }
        case GET_ADDRESS_BOOK:
            return{
                ...state,
                address_book: payload,
                loading: false
            }
        case VERIFY_WITHDRAW:
            return{
                ...state,
                verify_withdraw: payload,
                loading: false
            }    
        case DEPOSIT:
            return{
                ...state,
                deposit: payload,
                loading: false
            }
        case WITHDRAW: 
            return {
                ...state,
                withdraw: payload,
                loading: false
            }
        case WITHDRAWAL_FEE: 
            return {
                ...state,
                withdraw_fee: payload,
                loading: false
            }
        case WITHDRAWAL_FEE_ERROR: 
            return {
                ...state,
                withdraw_fee_error: payload,
                loading: false
            }
        case WITHDRAW_ERROR:
            return{
                ...state,
                withdrawal_error:payload,
                loading: false
            }
        case ADDRESS_BOOK_ERROR:
            return{
                ...state,
                address_book_error: payload,
                loading: false,
                address_book: null,
                create_address_book: null,
            }
        case WALLET_ERROR:
            return{
                ...state,
                wallet: null,
                wallets: null,
                deposit: null,
                withdraw: null,
                error: payload
            }
        default:
            return state;
    }

}


export default WalletReducer