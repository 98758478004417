import React, {FC, useEffect, Fragment} from "react";
import ReactGA from "react-ga";
import {useLocation} from "react-router";


interface IGAListenerProps {
  trackingId: string;
}

export const GAListener: FC<IGAListenerProps> = props => {
  const {children, trackingId} = props;
  const location = useLocation<{pathname: string; search: string}>();
  useEffect(() => {
    ReactGA.initialize(trackingId);
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(`${location.pathname}${location.search}`);
    
  }, [location, trackingId]);

  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
  return <Fragment>{children}</Fragment>;
};