import { IBridgeState, BridgeAction } from '../../types'
import { ACTIVATE_BRIDGE, ADD_BRIDGE_EXCHANGE_WALLET, BRIDGE_CASHOUT, BRIDGE_CASHOUT_ERROR, BRIDGE_ERROR, BRIDGE_EXCHANGE_ERROR, BRIDGE_EXCHANGE_WALLET_ERROR, BRIDGE_GENERATE_PAGA, BRIDGE_GENERATE_PAGA_ERROR, BRIDGE_ONE_OFF, BRIDGE_ONE_OFF_ERROR, BRIDGE_TRANSACTION_HISTORY_ERROR, BRIDGE_WELCOME_MESSAGE, BRIDGE_WITHDRAW, BRIDGE_WITHDRAW_ERROR, DELETE_BRIDGE_EXCHANGE_WALLET, GET_BRIDGE_BALANCE, GET_BRIDGE_EXCHANGE, GET_BRIDGE_EXCHANGES, GET_BRIDGE_EXCHANGE_WALLET, GET_BRIDGE_MARKET_RATE, GET_BRIDGE_TRANSACTIONS_HISTORY, GET_BRIDGE_WALLET, UPDATE_BRIDGE_EXCHANGE_WALLET } from './types'

const initialState:IBridgeState = {
    activate: {},
    bridge_wallet: {},
    bridge_balance: {},
    bridge_exchange:{},
    bridge_exchanges:[],
    add_bridge_exchange_wallet: {},
    update_bridge_exchange_wallet: {},
    delete_bridge_exchange_wallet: {},
    get_bridge_exchange_wallet: {},
    get_bridge_transactions_history: [],

    market_rate: {},
    bridge_welcome_message: {},

    bridge_one_off: {},
    bridge_one_off_error: {},
    
    bridge_generate_paga:{},
    bridge_generate_paga_error:{},

    bridge_cashout: {},
    
    bridge_withdraw: {},
    bridge_withdraw_error:null,
    
    bridge_cashout_error:{},
    bridge_transaction_history_error:null,
    bridge_exchange_wallet_error: null,
    bridge_exchange_error: null,
    loading: false,
    error:null
}

const BridgeReducer = (state = initialState, action:BridgeAction) => {
    const { type, payload } = action;

    switch (type) {
        case ACTIVATE_BRIDGE:
            return{
                ...state,
                active: payload,
                loading: true,
                error: null
            }
        case GET_BRIDGE_WALLET:
            return{
                ...state,
                bridge_wallet: payload,
                loading: true,
                error: null
            }
        
        case GET_BRIDGE_BALANCE:
            return{
                ...state,
                bridge_balance: payload,
                loading: true,
                error: null
            }
        case GET_BRIDGE_EXCHANGES:
            return{
                ...state,
                bridge_exchanges: payload,
                loading: true,
                error: null
            }
        case GET_BRIDGE_EXCHANGE:
            return{
                ...state,
                bridge_exchange: payload,
                loading: true,
                error: null
            }
        case ADD_BRIDGE_EXCHANGE_WALLET:
            return{
                ...state,
                add_bridge_exchange_wallet: payload,
                loading: true,
                error: null
            }
        case BRIDGE_WELCOME_MESSAGE:
            return{
                ...state,
                bridge_welcome_message: payload,
                loading: true,
                error: null
            }    
        case UPDATE_BRIDGE_EXCHANGE_WALLET:
            return{
                ...state,
                update_bridge_exchange_wallet: payload,
                loading: true,
                error: null
            }
        case GET_BRIDGE_EXCHANGE_WALLET:
            return{
                ...state,
                get_bridge_exchange_wallet: payload,
                loading: true,
                error: null
            }
        case DELETE_BRIDGE_EXCHANGE_WALLET:
            return{
                ...state,
                delete_bridge_exchange_wallet: payload,
                loading: true,
                error: null
            }
        case GET_BRIDGE_TRANSACTIONS_HISTORY:
            return{
                ...state,
                get_bridge_transactions_history: payload,
                loading: true,
                error: null
            }
        case GET_BRIDGE_MARKET_RATE:
            return{
                ...state,
                market_rate: payload,
                loading: true,
                error: null
            }
        case BRIDGE_WITHDRAW:
            return{
                ...state,
                bridge_withdraw: payload,
                loading: true,
                error: null
            }
        case BRIDGE_ONE_OFF: 
            return{
                ...state,
                bridge_one_off: payload,
                loading: true,
                error: null
            }
        case BRIDGE_GENERATE_PAGA:
            return{
                ...state,
                bridge_generate_paga: payload,
                loading: true,
            }
        case BRIDGE_GENERATE_PAGA_ERROR:
            return{
                ...state,
                bridge_generate_paga_error: payload,
                loading: true,
            }
        case BRIDGE_CASHOUT:
            return{
                ...state,
                bridge_cashout: payload,
                loading: true,
            }
        case BRIDGE_CASHOUT_ERROR:
            return{
                ...state,
                bridge_cashout_error: payload,
                loading: true,
            }
        case BRIDGE_WITHDRAW_ERROR:
            return{
                ...state,
                bridge_withdraw_error: payload,
                loading: true,
                error: null
            }
         case BRIDGE_TRANSACTION_HISTORY_ERROR: 
            return{
                ...state,
                activate: null,
                loading: true,
                bridge_transaction_history_error: payload
            }   
        case BRIDGE_EXCHANGE_WALLET_ERROR: 
            return{
                ...state,
                activate: null,
                loading: true,
                bridge_exchange_wallet_error: payload
            }
        case BRIDGE_EXCHANGE_ERROR: 
            return{
                ...state,
                activate: null,
                loading: true,
                bridge_exchange_error: payload
            }
        case BRIDGE_ONE_OFF_ERROR: 
            return{
                ...state,
                bridge_one_off_error: payload,
                loading: true,
                error: null
            }
        case BRIDGE_ERROR: 
            return{
                ...state,
                activate: null,
                loading: true,
                error: payload
            }
        default:
            return state;
    }

}

export default BridgeReducer 