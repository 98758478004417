import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Apple from '../../../images/New/apple-white.svg';
import Android from '../../../images/New/android-white.svg';
import ArrowRight from '../../../images/New/arrow-right-white.svg';
import AppImage from '../../../images/New/sec-mobile-app.svg';
import AppImageMobile from '../../../images/New/mobile-sec-mobile-app.svg';
import useWindowDimensions from '../../WindowDimension/WindowDimension';

type Props = {
  DownloadApp: () => void;
};

const NewSectionSix: React.FC<Props> = ({ DownloadApp }) => {
  const { width } = useWindowDimensions();

  return (
    <div className="scalex-new-section-six" id="download">
      <Container>
        <div className="scalex-new-section-six__wrapper">
          <Row>
            <Col md="5">
              <h4>Start enjoying seamless and secure crypto trading</h4>
              <Button
                className="scalex-new-section-three__left--button scalex-new-section-six--button-spacing"
                onClick={DownloadApp}
              >
                <img
                  src={Apple}
                  className="download-image-spacing"
                  alt="apple icon"
                />
                <img src={Android} alt="android icon" />
                <span className="scalex-new-section-three__left--line" />
                Get started
                <img
                  src={ArrowRight}
                  className="img-spacing"
                  alt="android icon"
                />
              </Button>
            </Col>
            <Col md="7">
              <div className="d-flex align-items-center">
                {width <= 780 ? (
                  <img
                    src={AppImageMobile}
                    className="scalex-new-section-six--right-image"
                    alt="app mobile "
                  />
                ) : (
                  <img
                    src={AppImage}
                    className="scalex-new-section-six--right-image"
                    alt="app icon"
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default NewSectionSix;
