import { SliderContent } from '../types';

export const options = {
  loop: true,
  nav: false,
  dots: false,
  margin: 30,
  autoplay: false,
  autoplayTimeout: 2000,
  smartSpeed: 1200,
  autoplayHoverPause: true,
  lazyLoad: true,
  // touchDrag: false,
  // pullDrag: false,
  // mouseDrag: false,
  responsive: {
    0: {
      items: 1
    },
    500: {
      items: 2
    },
    992: {
      items: 3
    },
    1300: {
      items: 4
    }
  }
};

export const option2 = {
  loop: true,
  nav: false,
  dots: false,
  autoplay: true,
  margin: 30,
  autoplayTimeout: 4000,
  smartSpeed: 1200,
  autoplayHoverPause: true,
  lazyLoad: true,
  responsive: {
    0: {
      items: 1
    },
    576: {
      items: 2
    },
    1550: {
      items: 3
    }
  }
};

export const option3 = {
  loop: true,
  nav: false,
  dots: false,
  autoplay: true,
  autoplayTimeout: 4000,
  smartSpeed: 1200,
  autoplayHoverPause: true,
  lazyLoad: true,
  responsive: {
    0: {
      items: 2
    },
    768: {
      items: 3
    },
    992: {
      items: 4
    },
    1200: {
      items: 6
    }
  }
};

export const option4 = {
  loop: false,
  nav: false,
  autoWidth: false,
  dots: false,
  autoplay: true,
  autoplayTimeout: 4000,
  rewind: true,
  smartSpeed: 1200,
  autoplayHoverPause: true,
  lazyLoad: true,
  responsive: {
    0: {
      items: 1
    },
    768: {
      items: 2
    },
    992: {
      items: 2
    },
    1200: {
      items: 3
    }
  }
};

// Slick Carousel data

export const SlickContent: SliderContent[] = [
  {
    id: 1,
    content: `I've been using scalex for some time now and I can say it's been worthwhile and in the case of any issues, response is so quick; `,
    image: '../../../images/New/testimonial-image-01.svg',
    name: 'Baelish',
    role: 'Customer'
  },
  {
    id: 2,
    content:
      'Easy to use, less than 1min transfer to your bank account, bridge section is the best. Low transaction fees. No downtime. ',
    image: '../../../images/New/testimonial-image-01.svg',
    name: 'Jk.',
    role: 'Customer'
  },
  {
    id: 3,
    content:
      'The two most worrying things in crypto are security risk and reliability. Scalex has proven to be very effective in both',
    image: '../../../images/New/testimonial-image-01.svg',
    name: 'IZu ukaozor',
    role: 'Customer'
  },
  {
    id: 4,
    content: `No hitches, no glitches, safe, fast, secure and reliable are but a few words I can use to qualify my experience`,
    image: '../../../images/New/testimonial-image-01.svg',
    name: 'Pope',
    role: 'Customer'
  }
  // {
  //   id: 5,
  //   content:
  //     'Magna fermentum iaculis eu non diam phasellus vestibulum Cras semper auctor neque vitae tempus quam. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit. Porta nibh venenatis cras sed felis eget velit aliquet. Semper eget duis at tellus at',
  //   image: '../../../images/New/testimonial-image-01.svg',
  //   name: 'Kathryn Murphy',
  //   role: 'Fashion designer',
  // },
];

export const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  speed: 4000,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const settingsTwo = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 3,
  autoplay: true,
  arrows: false,
  speed: 4000,
  autoplaySpeed: 4000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    }
  ]
};
