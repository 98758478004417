import { INotificationState, NotificationAction } from "../../types";
import { GET_NOTIFICATIONS, NOTIFICATION_ERROR, READ_NOTIFICATION, UNSEEN_NOTIFICATIONS } from "./types";


const initialState:INotificationState = {
    notifications: [],
    read_notification:{},
    unseen:[],
    loading: true,
    error: null
}

const NotificationReducer = (state = initialState, action:NotificationAction) => {
    const { type, payload } = action

    switch (type) {
        case GET_NOTIFICATIONS:
            return{
                ...state,
                notifications:payload,
                loading: false,
                error: null
            }
        case READ_NOTIFICATION:
            return{
                ...state,
                read_notification: payload,
                loading: false,
                error: null
            }
        case UNSEEN_NOTIFICATIONS:
            return{
                ...state,
                unseen: payload,
                loading: false,
                error: null
            }
        case NOTIFICATION_ERROR:
            return{
                ...state,
                read_notification: null,
                notifications:null,
                error: payload
            }
        default:
            return state;
    }
}

export default NotificationReducer