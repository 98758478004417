import { v4 as uuid } from 'uuid';
import { DispatchAlertType } from '../../types';
import { SET_ALERT, REMOVE_ALERT } from './types';


export const setAlert = (msg:string, alertType: string, timeout = 5000) => (dispatch: DispatchAlertType) => {
    const id = uuid;
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });
  
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
  
  export const setMessage = (msg:string, alertType: string, timeout = 10000) => (dispatch: DispatchAlertType) => {
    const id = uuid;
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
  