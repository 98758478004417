import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import About from '../../../images/New/about-image-02.svg';
import AboutMobile from '../../../images/New/about-image-mobile.svg';

import CloseModal from '../../../images/New/close-modal.svg';
import useWindowDimensions from '../../WindowDimension/WindowDimension';

type Props = {
  modal: boolean;
  toggle: any;
  children: JSX.Element;
};

const ModalLayout: React.FC<Props> = ({ modal, toggle, children }) => {
  const { width } = useWindowDimensions();

  return (
    <Modal isOpen={modal} toggle={toggle} className="scalex-new-modal">
      <ModalBody>
        <div className="d-flex scalex-new-modal__wrapper">
          {width <= 780 ? (
            <img
              src={AboutMobile}
              alt="about"
              className="scalex-new-modal--image"
            />
          ) : (
            <img
              src={About}
              alt="about"
              width={537}
              className="scalex-new-modal--image"
            />
          )}

          {children}
        </div>
        <div onClick={toggle} className="scalex-new-modal--close">
          <img src={CloseModal} alt="about" />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalLayout;
