import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import NewLandingLayout from '../../../components/NewLandingComponents/NewLandingLayout/NewLandingLayout';

interface Props {}

const DataProtectPolicy: React.FC<Props> = () => {
  return (
    <NewLandingLayout>
      <div className="scalex-new-privacy-policy">
        <Container>
          <Row>
            <Col>
              <h2>Data Protection Policy</h2>

              <div className="scalex-new-privacy-policy__wrapper">
                <h4 className="mt-5">1.0 Introduction</h4>
                <p>
                  Scalex Technologies Limited, hereinafter referred to as "the
                  Company," is committed to protecting the privacy and
                  confidentiality of personal information entrusted to us by our
                  clients, employees, and other stakeholders. This Data
                  Protection Policy outlines our commitment to complying with
                  relevant data protection laws in Nigeria and establishing
                  robust practices to safeguard sensitive information.
                </p>

                <div>
                  <h4>2.0 Scope </h4>
                  <p>
                    This policy applies to all employees, contractors, and third
                    parties who process personal information on behalf of the
                    Company.
                  </p>

                  <h4 className="mt-5"> 3.0 Types of Data Collected</h4>
                  <p>
                    The Company adheres to the relevant data protection laws and
                    regulations in Nigeria, including but not limited to the
                    Nigeria Data Protection Regulation (NDPR) and any other
                    applicable legislation.
                  </p>
                  <h4 className="mt-5">4.0 Data Collection and Processing:</h4>

                  <h4 className="mt-5">
                    4.1 Lawfulness, Fairness, and Transparency:
                  </h4>
                  <span>
                    - We will only collect and process personal information for
                    legitimate purposes, ensuring transparency in our data
                    processing activities.
                  </span>

                  <h4 className="mt-5"> 4.2 Purpose Limitation:</h4>
                  <span>
                    - Personal information will be collected for specified,
                    explicit, and legitimate purposes and will not be further
                    processed in a manner incompatible with those purposes.
                  </span>

                  <h4 className="mt-5"> 4.3 Data Minimization: </h4>

                  <p>
                    - We will only collect and process data that is adequate,
                    relevant, and limited to what is necessary for the purposes
                    for which it is processed.
                  </p>

                  <h4 className="mt-5">5. Data Security: </h4>

                  <h4 className="mt-5"> 5.1 Organizational Measures:</h4>
                  <p>
                    - The Company will implement and maintain appropriate
                    technical and organizational measures to ensure the security
                    of personal information.
                  </p>

                  <h4 className="mt-5">5.2 Access Controls:</h4>
                  <p>
                    - Access to personal information will be restricted to
                    authorized individuals, and access controls will be
                    regularly reviewed and updated.
                  </p>

                  <h4 className="mt-5">5.3 Data Encryption:</h4>
                  <p>
                    - Personal information will be encrypted during transmission
                    and storage to prevent unauthorized access.
                  </p>

                  <h4 className="mt-5">6. Data Subject Rights</h4>

                  <h4 className="mt-5">6.1 Access and Correction:</h4>
                  <p>
                    - Data subjects have the right to access their personal
                    information held by the Company and request corrections
                    where necessary.
                  </p>
                  <h4 className="mt-5">6.2 Data Portability:</h4>

                  <p>
                    - The Company will facilitate the portability of personal
                    information in a commonly used, machine-readable format upon
                    request.
                  </p>

                  <h4 className="mt-5">7. Data Breach Response: </h4>
                  <h4 className="mt-5">7.1 Notification: </h4>

                  <p>
                    - In the event of a data breach, the Company will promptly
                    notify the appropriate regulatory authorities and affected
                    data subjects, as required by applicable laws.
                  </p>

                  <h4 className="mt-5">7.2 Investigation:</h4>
                  <p>
                    - The Company will conduct a thorough investigation into the
                    causes of the breach and take corrective actions to prevent
                    recurrence.
                  </p>
                  <h4 className="mt-5">8. Data Protection Officer (DPO): </h4>
                  <h4 className="mt-5">8.1 Appointment: </h4>

                  <p>
                    - The Company will appoint a Data Protection Officer
                    responsible for overseeing compliance with this policy and
                    relevant data protection laws.
                  </p>
                  <h4 className="mt-5">8.2 Contact Information:</h4>

                  <p>
                    - The DPO's contact information will be made available to
                    all employees and data subjects for inquiries related to
                    data protection.
                  </p>

                  <h4 className="mt-5">9. Training and Awareness: </h4>
                  <h4 className="mt-5">9.1 Employee Training: </h4>

                  <p>
                    - All employees will undergo regular training on data
                    protection principles and compliance with this policy.
                  </p>
                  <h4 className="mt-5">9.2 Awareness Campaigns: </h4>

                  <p>
                    - The Company will conduct awareness campaigns to inform
                    stakeholders about their rights and the Company's data
                    protection practices.
                  </p>
                  <h4 className="mt-5">10. Policy Review and Updates: </h4>
                  <h4 className="mt-5">10.1 Regular Review: </h4>

                  <p>
                    - This policy will be reviewed regularly to ensure its
                    effectiveness and compliance with evolving data protection
                    laws.
                  </p>

                  <h4 className="mt-5">10.2 Updates:</h4>
                  <p>
                    - Any updates to this policy will be communicated to all
                    relevant stakeholders.
                  </p>

                  <h4 className="mt-5">11. Conclusion: </h4>
                  <p>
                    This Data Protection Policy reflects the commitment of
                    Scalex Technologies Limited to maintaining the highest
                    standards of data protection in accordance with Nigerian
                    legislation. All employees and stakeholders are expected to
                    comply with this policy to ensure the confidentiality and
                    integrity of personal information.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </NewLandingLayout>
  );
};

export default DataProtectPolicy;
