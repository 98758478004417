// Deals Types

export const CREATE_DEAL = 'CREATE_DEAL'

export const ACCEPT_SELL_RENEGOTIATED_DEAL = 'ACCEPT_SELL_RENEGOTIATED_DEAL'

export const ACCEPT_SELL_RENEGOTIATED_DEAL_ERROR = 'ACCEPT_SELL_RENEGOTIATED_DEAL_ERROR'

export const CREATE_DEAL_ERROR = 'CREATE_DEAL_ERROR'

export const GET_DEAL ='GET_DEAL'

export const GET_DEALS ='GET_DEALS'

export const GET_PENDING_DEALS = 'GET_PENDING_DEALS'

export const NEGOTIATE_DEAL = 'NEGOTIATE_DEAL'

export const DECLINE_DEAL = 'DECLINE_DEAL'

export const CANCEL_DEAL = 'CANCEL_DEAL'

export const ACCEPT_DEAL = 'ACCEPT_DEAL'

export const PAY_NOW = "PAY_NOW"

export const PAY_WITH_PAGA = "PAY_WITH_PAGA"
export const PAY_WITH_PAGA_ERROR = "PAY_WITH_PAGA_ERROR"

export const PAY_WITH_PAYAZA = 'PAY_WITH_PAYAZA'
export const PAY_WITH_PAYAZA_ERROR = 'PAY_WITH_PAYAZA_ERROR'

export const DEAL_ERROR = 'DEAL_ERROR'