import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { AccordionData } from '../../../utils/orderData';
import NewAccordion from '../NewAccordion/NewAccordion';

import BridgeAccordion from '../../../images/New/bridge-accordion.svg';
import P2PAccordion from '../../../images/New/p2p-accordion.svg';
import Web3Accordion from '../../../images/New/web3-accordion.svg';
import APIAccordion from '../../../images/New/api-accordion.svg';
import OTCAccordion from '../../../images/New/otc-accordion.svg';

import BridgeAccordionMobile from '../../../images/New/bridge-accordion-mobile.png';
import P2PAccordionMobile from '../../../images/New/p2p-accordion-mobile.png';
import Web3AccordionMobile from '../../../images/New/web3-accordion-mobile.png';
import APIAccordionMobile from '../../../images/New/api-accordion-mobile.png';
import OTCAccordionMobile from '../../../images/New/otc-accordion-mobile.png';

import ArrowRight from '../../../images/New/arrowRight.svg';
import useWindowDimensions from '../../WindowDimension/WindowDimension';
import ProductModalLayout from '../ModalLayout/ProductModalLayout';

type Props = {
  handleContactModal: () => void;
  DownloadApp: () => void;
};

const NewSectionFour: React.FC<Props> = ({
  handleContactModal,
  DownloadApp
}) => {
  const [selectedItem, setSelectedItem] = useState(1);
  const [modal, setModal] = useState(false);
  const { width } = useWindowDimensions();

  const handleModal = () => setModal(!modal);

  const DownloadButton = () => (
    <Button className="scalex-custom-small-button mr-4" onClick={DownloadApp}>
      Download app
    </Button>
  );

  const ContactButton = () => (
    <Button
      className="scalex-custom-small-button mr-4"
      onClick={handleContactModal}
    >
      Contact us
    </Button>
  );

  const LearnMore = () => (
    <div className="d-flex align-items-center cursor" onClick={handleModal}>
      <span>
        <h5>Learn more</h5>
      </span>
      <img src={ArrowRight} alt="icon" width={10} height={10} />
    </div>
  );

  return (
    <div className="scalex-new-section-four" id="products">
      <Container>
        <Row>
          <Col md="6" sm="12">
            <div className="scalex-new-section-four__left">
              <h3>
                Discover the Power of <br />
                Scalex's Crypto Solutions
              </h3>
              <div>
                {AccordionData.map((accord: any, i: number) => (
                  <NewAccordion
                    title={accord.title}
                    id={accord.id}
                    key={i}
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                  >
                    <p className="scalex-new-section-four__left--paragraph">
                      {accord.content}
                    </p>
                    <div className="scalex-new-section-four__left--image">
                      {width <= 760 && (
                        <img
                          src={
                            selectedItem === 1
                              ? BridgeAccordionMobile
                              : selectedItem === 2
                              ? P2PAccordionMobile
                              : selectedItem === 3
                              ? Web3AccordionMobile
                              : selectedItem === 5
                              ? OTCAccordionMobile
                              : APIAccordionMobile
                          }
                          alt="icon"
                          className="mb-4"
                        />
                      )}
                    </div>
                    <div className="scalex-new-section-four__left__actions d-flex align-items-center">
                      {selectedItem === 1 || selectedItem === 2 ? (
                        <DownloadButton />
                      ) : null}
                      {selectedItem === 5 || selectedItem === 4 ? (
                        <ContactButton />
                      ) : (
                        <LearnMore />
                      )}
                    </div>
                  </NewAccordion>
                ))}
              </div>
            </div>
          </Col>
          {width >= 760 && (
            <Col md="6" sm="12">
              <div className="d-flex align-items-end">
                <img
                  src={
                    selectedItem === 1
                      ? BridgeAccordion
                      : selectedItem === 2
                      ? P2PAccordion
                      : selectedItem === 3
                      ? Web3Accordion
                      : selectedItem === 5
                      ? OTCAccordion
                      : APIAccordion
                  }
                  className="scalex-new-section-four--right-image"
                  alt="icon"
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <ProductModalLayout
        modal={modal}
        toggle={handleModal}
        selectedItem={selectedItem}
      >
        <div className="scalex-new-product-modal__right-content right-content-margin-option-2">
          {selectedItem === 1 ? (
            <div className="scalex-new-product-modal__right-content__middle-2">
              <h3 className="middle-content-title-spacing-option-3">Bridge</h3>
              <p>
                Scalex Bridge is a groundbreaking solution that enables users to
                instantly buy, fund wallets, receive, and convert
                cryptocurrencies to fiat currencies seamlessly. With Bridge,
                users can hedge against local currency devaluation by converting
                their earnings into stablecoins, ensuring the protection of
                their assets in the ever-evolving crypto landscape.
              </p>
              <p>
                The bridge feature from Scalex is not only an efficient and
                secure on and off-ramp for the African market, but it also
                serves as a gateway for global users to access digital assets.
                By prioritizing user experience and offering competitive
                exchange rates, Bridge has become an indispensable tool for
                those looking to tap into the world of cryptocurrencies with
                ease and confidence.
              </p>

              <div className="d-flex align-items-center mt-5">
                <Button
                  className="scalex-custom-small-button mr-4"
                  onClick={DownloadButton}
                >
                  Download app
                </Button>
                <div className="close-window cursor" onClick={handleModal}>
                  <h6>Close window</h6>
                </div>
              </div>
            </div>
          ) : selectedItem === 2 ? (
            <div className="scalex-new-product-modal__right-content__middle-2">
              <h3 className="middle-content-title-spacing-option-3">
                SCALEX P2P
              </h3>
              <p>
                Scalex P2P is a dedicated to fostering a secure and
                user-friendly environment for peer-to-peer cryptocurrency
                trading. It supports an extensive array of cryptocurrencies and
                offers a seamless experience that streamlines transactions while
                minimizing the risk of fraud, enabling users to trade with
                confidence.
              </p>
              <p>
                With a robust escrow system at its core, Scalex P2P promotes
                trust and transparency among its users. Our platform nurtures a
                thriving community of traders, making our product the ideal
                choice for those seeking a secure and reliable venue for
                peer-to-peer cryptocurrency transactions.
              </p>

              <div className="d-flex align-items-center mt-5">
                <Button
                  className="scalex-custom-small-button mr-4"
                  onClick={DownloadButton}
                >
                  Download app
                </Button>
                <div className="close-window cursor" onClick={handleModal}>
                  <h6>Close window</h6>
                </div>
              </div>
            </div>
          ) : selectedItem === 3 ? (
            <div className="scalex-new-product-modal__right-content__middle-2">
              <h3 className="middle-content-title-spacing-option-3">W3 RAMP</h3>
              <p>
                The W3 Ramp by Scalex is an innovative solution designed to
                bridge the gap between traditional finance and the decentralized
                finance (DeFi) sector. In its beta-testing stage, W3 Ramp
                already simplifies the adoption of DeFi protocols, NFT
                marketplaces, and Play-to-Earn platforms, making it easier for
                users to transition between the two worlds.
              </p>
              <p>
                By lowering the barriers to entry and providing a seamless
                experience, Scalex’s W3 Ramp is poised to play a crucial role in
                driving mass adoption of decentralized financial applications,
                unlocking new opportunities for both businesses and individual
                users.
              </p>

              <div className="d-flex align-items-center mt-5">
                <Button className="scalex-custom-small-button mr-4">
                  <a
                    href="https://checkout.scalex.africa/"
                    className="learn-more-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </Button>
                <div className="close-window cursor" onClick={handleModal}>
                  <h6>Close window</h6>
                </div>
              </div>
            </div>
          ) : selectedItem === 4 ? (
            <div className="scalex-new-product-modal__right-content__middle-2">
              <h3 className="middle-content-title-spacing-option-3">
                Scalex APIs and SDKs
              </h3>
              <p>
                The suite of APIs and SDKs offered by Scalex allows businesses
                and developers to effortlessly integrate the company's
                state-of-the-art solutions into their platforms. These tools
                provide streamlined access to liquidity infrastructure, on and
                off-ramp services, and other essential features, expanding
                businesses' capabilities and enhancing user experience.
              </p>
              <p>
                Backed by comprehensive documentation and dedicated support,
                Scalex ensures a seamless integration process for businesses
                looking to leverage its cutting-edge technology, enabling them
                to better serve their customers and stay ahead of the
                competition.
              </p>

              <div className="d-flex align-items-center mt-5">
                <div className="close-window cursor" onClick={handleModal}>
                  <h6>Close window</h6>
                </div>
              </div>
            </div>
          ) : (
            <div className="scalex-new-product-modal__right-content__middle-2">
              <h3 className="middle-content-title-spacing-option-3">
                OTC DESK
              </h3>
              <p>
                Scalex's Over-the-Counter (OTC) Desk is tailored for
                institutional and high-volume traders seeking a personalized
                trading experience. Our OTC Desk provides direct access to deep
                liquidity and competitive pricing, ensuring swift and secure
                execution of large transactions while minimizing the risk of
                price slippage.
              </p>

              <div className="d-flex align-items-center mt-5">
                <Button
                  className="scalex-custom-small-button mr-4"
                  onClick={DownloadButton}
                >
                  Download app
                </Button>
                <div className="close-window cursor" onClick={handleModal}>
                  <h6>Close window</h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </ProductModalLayout>
    </div>
  );
};

export default NewSectionFour;
