export const ACTIVATE_BRIDGE = 'ACTIVATE_BRIDGE'
export const GET_BRIDGE_WALLET = 'GET_BRIDGE_WALLET'
export const GET_BRIDGE_BALANCE = 'GET_BRIDGE_BALANCE'

export const GET_BRIDGE_EXCHANGE = 'GET_BRIDGE_EXCHANGE'
export const GET_BRIDGE_EXCHANGES = 'GET_BRIDGE_EXCHANGES'

export const ADD_BRIDGE_EXCHANGE_WALLET = 'ADD_BRIDGE_EXCHANGE_WALLET'
export const UPDATE_BRIDGE_EXCHANGE_WALLET = 'UPDATE_BRIDGE_EXCHANGE_WALLET'
export const DELETE_BRIDGE_EXCHANGE_WALLET = 'DELETE_BRIDGE_EXCHANGE_WALLET'
export const GET_BRIDGE_EXCHANGE_WALLET = 'GET_BRIDGE_EXCHANGE_WALLET'

export const GET_BRIDGE_MARKET_RATE = 'GET_BRIDGE_MARKET_RATE'

export const GET_BRIDGE_TRANSACTIONS_HISTORY = 'GET_BRIDGE_TRANSACTIONS_HISTORY'
export const GET_BRIDGE_TRANSACTION_HISTORY = 'GET_BRIDGE_TRANSACTIONS_HISTORY'

export const BRIDGE_WITHDRAW = 'BRIDGE_WITHDRAW'
export const BRIDGE_CASHOUT = 'BRIDGE_CASHOUT'

export const BRIDGE_ONE_OFF = 'BRIDGE_ONE_OFF'
export const BRIDGE_ONE_OFF_ERROR = 'BRIDGE_ONE_OFF_ERROR'

export const BRIDGE_GENERATE_PAGA = 'BRIDGE_GENERATE_PAGA'
export const BRIDGE_GENERATE_PAGA_ERROR = 'BRIDGE_GENERATE_PAGA_ERROR'

export const BRIDGE_WELCOME_MESSAGE = 'BRIDGE_WELCOME_MESSAGE'

export const BRIDGE_WITHDRAW_ERROR = 'BRIDGE_WITHDRAW_ERROR'
export const BRIDGE_CASHOUT_ERROR = 'BRIDGE_CASHOUT_ERROR'
export const BRIDGE_TRANSACTION_HISTORY_ERROR = 'BRIDGE_TRANSACTION_HISTORY_ERROR'
export const BRIDGE_EXCHANGE_WALLET_ERROR = 'BRIDGE_EXCHANGE_WALLET_ERROR'
export const BRIDGE_EXCHANGE_ERROR = 'BRIDGE_EXCHANGE_ERROR'
export const BRIDGE_ERROR = 'BRIDGE_ERROR'